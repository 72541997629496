// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// User
export const USER_PUT = "USER_PUT";
export const USER_GET = "USER_GET";
export const USER_LOGIN = "USER_LOGIN";
export const USER_IS_AUTH = "USER_IS_AUTH";
export const USER_ERROR_AUTH = "USER_ERROR_AUTH";

// Card
export const CART_LOADING = "CART_LOADING";
export const CART_PUT = "CART_PUT";
export const CART_GET = "CART_GET";
export const CART_ADD = "CART_ADD";
export const CART_CHANGE = "CART_CHANGE";
export const CART_REMOVE = "CART_REMOVE";
export const CART_CLEAN = "CART_CLEAN";


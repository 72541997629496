import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './formSettings.scss';
import Button from '../ui/Button';
import TextField from '../ui/TextField';
import {validHandler} from '../../utils/validation';
import {setUserUpdate} from '../../services/api';
import SettingTitle from '../ui/SettingTitle';
import Spinner from '../Spinner';

const ApiMetrcInformation = ({user}) => {
  const [errors, setErrors] = useState([]);
  const [lockForm, setLockForm] = useState(false);
  const [needValid, setNeedValid] = useState(false);
  const [fields, setFields] = useState({metrcApiKey: user.metrcApiKey});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (needValid) {
      const validation = ['metrcApiKey'];
      const {errors} = validHandler(fields, validation);
      setErrors(errors);
    }
  }, [fields]);


  const changeHandler = e => {
    const f = {...fields};
    f[e.target.name] = e.target.value;
    setFields(f);
  };


  const saveMetrcApiKey = e => {
    e.preventDefault();
    setNeedValid(true);
    setLockForm(true);
    setLoading(true);
    if (!errors.length) {
      setUserUpdate(fields)
        .then(() => {
          setLockForm(false);
          setLoading(false);
        });
    }
  };

  return (
    <form onSubmit={saveMetrcApiKey}>
      <div className="form-settings__license overall-design">
        <SettingTitle>
          Api Metrc Key
        </SettingTitle>
        {loading && <Spinner overlay />}
        <div className="form-settings__wrapper">
          <div className="row">
            <div className="col8">
              <TextField
                id="metrcApiKey"
                type="text"
                fieldName="Metrc Api key"
                value={fields['metrcApiKey']}
                errors={errors}
                onChange={changeHandler}
                disabled={lockForm}
              />
            </div>
            <div className="col4">
              <Button type="submit" color="" width="width">Save</Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};


ApiMetrcInformation.propTypes = {
  user: PropTypes.any
};

export default ApiMetrcInformation;
import React from 'react';
import {Link} from 'react-router-dom';
import './metrckey.scss';

const Metrckey = () =>
	<Link to="/inventory" className="metrckey">
		<div className="metrckey_icon"></div>
		<div className="metrckey_text">
			Don&apos;t forget to enter your Metric API Key to start selling
		</div>
		<div className="metrckey_arrow"></div>
	</Link>;

export default Metrckey;
import React from 'react';
import PropTypes from 'prop-types';
const ORDER_STATUS = [
  {id: 1, title: 'New'},
  {id: 2, title: 'Accepted'},
  {id: 3, title: 'Payed'},
  {id: 4, title: 'Delivered'}
];

const Status = ({status}) => {
  const s = ORDER_STATUS.find(s => s.id == status);
  return <>
    {s && s.title}
  </>;
};

Status.propTypes = {
  status: PropTypes.any
};

export default Status;

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {ReactComponent as DelimeterIcon} from './delimeter.svg';
import './breadCrumbs.scss';


const BreadCrumbs = ({breadcrumbs}) =>
	<ul className="breadcrumbs">
		<li>
			<Link to="/" className="breadcrumbs__link">Home</Link>
		</li>

		{breadcrumbs && breadcrumbs.map((item, i) =>
			<React.Fragment key={i}>
				<li>
					<DelimeterIcon />
				</li>
				<li>
					{item.link
						? <Link to={item.link} className="breadcrumbs__link">{item.title}</Link>
						: <span className="breadcrumbs__link">{item.title}</span>
					}
				</li>
			</React.Fragment>
		)}

	</ul>;

BreadCrumbs.propTypes = {
	breadcrumbs: PropTypes.array
};

export default BreadCrumbs;
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import RModal from 'react-modal';
import Button from '../components/ui/Button';
import {getUrlForUpload, putFileOnAmazon, setMedia} from '../services/api';
import Spinner from '../components/Spinner';

const useModalAddImage = (onSavedMedia) => {

  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imagePrev, setImagePrev] = useState([]);
  const [inventoryId, setInventoryId] = useState(null);

  const openModal = (e, inventoryId) => {
    e.preventDefault();
    setIsOpen(true);
    console.log(inventoryId);
    setInventoryId(inventoryId);
  };

  const customStyles = {
    overlay: {
      background: 'rgba(0,0,0,.5)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '50px',
      background: '#171722'
    },
  };


  const onImageChange = e => {
    e.preventDefault();
    const files = e.target.files;
    Array.prototype.forEach.call(files, function (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages(prev => [...prev, file]);
        setImagePrev(prev => [...prev, reader.result]);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleUpload = () => {
    setLoading(true);

    images.forEach((file, i) => {
      let fileKey;
      getUrlForUpload({
        contentType: file.type,
        fileName: file.name
      })
        .then(res => {
          fileKey = res.key;
          return putFileOnAmazon({url: res.uploadUrl, file});
        })
        .then(() => {
          return setMedia({fileName: fileKey, inventoryId});
        })
        .then(() => {
          if (i >= images.length - 1) {
            setImages([]);
            setImagePrev([]);
            if (onSavedMedia) {
              setTimeout(() => {
                setLoading(false);
                onSavedMedia();
                setIsOpen(false);
              }, 1500);
            }
          }
        }).catch(e => {
          console.error(e);
        });
    });
  };

  const ModalAddImage = () =>
    <RModal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
      style={customStyles}
    >
      <div className="inventory__upload-image">
        <form encType="multipart/form-data" >
          <input
            multiple
            type="file"
            id="uploadImage"
            name="uploadImage"
            onChange={onImageChange}
          />
          <div className="inventory__upload-text">
            <Button width="width">Select files</Button>
            or <br />
            drag to upload.
          </div>
        </form>
        <div className="inventory__upload-images">
          {imagePrev.length > 0 && imagePrev.map((img, i) =>
            <img key={i} alt="" src={img} />
          )}
        </div>
        {images.length > 0 && <Button onClick={handleUpload}>Upload</Button>}
        {loading && <Spinner overlay />}
        <button className="inventory__upload-close" onClick={() => setIsOpen(false)}>✕</button>

      </div>
    </RModal >
    ;

  return {
    openModal,
    ModalAddImage
  };
};

useModalAddImage.propTypes = PropTypes.any;

export default useModalAddImage;

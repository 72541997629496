import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {ReactComponent as NoPhotoIcon} from './NoPhoto2.svg';
import './marketplaceGrid.scss';
import CleanButton from '../ui/CleanButton';
import {AddToCartIcon} from '../../assets/img/icons';

const MarketplaceGrid = ({item, addToCard}) =>
	<div data-id={item.id} className="marketplace-grid">
		<Link to={`/marketplace/${item.id}`}>
			<div className="marketplace-grid__image">
				{!item.media &&
					<span className="marketplace-grid__nophoto">
						<NoPhotoIcon /> No Photo
					</span>
				}
				{item.media && item.media.slice(0, 1).map(file =>
					<img key={file.preview} src={file.preview} alt="image" />
				)}
			</div>
			<div className="marketplace-grid__name">{item.name}</div>

			<div className="marketplace-grid__characteristics">
				<ul>
					<li><span>THC: {item.thc} %</span></li>
				</ul>
			</div>

			<div className="marketplace-grid__units">
				{item.quantity} {item.unitOfMeasureName}
			</div>

		</Link>
		<div className="marketplace-grid__bottom">
			<div className="marketplace-grid__price">
				$ {item.price}
			</div>

			<CleanButton
				icon={AddToCartIcon}
				className="marketplace-grid__card"
				onClick={() => addToCard(item.id, 1)}
			/>

		</div>
	</div>;

MarketplaceGrid.propTypes = {
	item: PropTypes.any,
	addToCard: PropTypes.any
};

export default MarketplaceGrid;
import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../../utils/classNames';
import './button.scss';

const Button = ({children, type, color, onClick, disabled, width}) => {
  return (
    <button
      className={classes(
        'button',
        color,
        width
      )}
      type={type ? type : 'button'}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.any,
  disabled: PropTypes.bool,
  width: PropTypes.string
};

export default Button;
import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import './report.scss';

import {MoneyIcon, BoxIcon, ChartIcon} from './icons';

const ReportItem = ({icon, data, measure, description}) => (
  <div className="report__item">
    <div className="report__icon">
      {icon}
    </div>
    <div className="report__data">
      <div className="report__data-title">
        {data}{' '}
        <span>{measure}</span>
      </div>
      <div className="report__data-desc">
        {description}
      </div>
    </div>
  </div>
);

ReportItem.propTypes = {
  icon: PropTypes.any,
  data: PropTypes.any,
  measure: PropTypes.any,
  description: PropTypes.any,
};

const Report = () => {
  const user = useSelector(state => state.user.user);
  console.log(user);

  return (
    <div className="report">
      <div className="report__period">
        <input type="radio" name="period" id="monthly" defaultChecked />
        <label htmlFor="monthly" className="report__period-button" >Monthly</label>
        <input type="radio" name="period" id="yearly" />
        <label htmlFor="yearly" className="report__period-button">Yearly</label>
      </div>
      <div className="report__block">
        <ReportItem
          icon={<MoneyIcon />}
          data='0.00'
          measure='USD'
          description='Total revenue generated'
        />
        <ReportItem
          icon={<BoxIcon />}
          data='0'
          measure='No'
          description='Total units sold'
        />
        <ReportItem
          icon={<ChartIcon />}
          data='0'
          measure=''
          description='% increase in sales'
        />
      </div>
    </div>
  );
};



export default Report;
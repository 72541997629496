import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './formSettings.scss';
import Button from '../ui/Button';
import TextField from '../ui/TextField';
import {validHandler} from '../../utils/validation';
import {setUserUpdate} from '../../services/api';
import SelectField from '../ui/SelectField';
import {STATES} from '../../constants';
import SettingTitle from '../ui/SettingTitle';
import Spinner from '../Spinner';

const CompanyInformation = ({user}) => {
	const [errors, setErrors] = useState([]);

	const [lockForm, setLockForm] = useState(false);
	const [needValid, setNeedValid] = useState(false);
	const [loading, setLoading] = useState(false);

	const [fields, setFields] = useState({companyName: user.companyName, position: user.position, companyAddress: user.companyAddress, city: user.city, state: user.state, zipCode: user.zipCode, phone: user.phone, ein: user.ein});

	useEffect(() => {
		if (needValid) {
			const validation = ['email'];
			const {errors} = validHandler(fields, validation);
			setErrors(errors);
		}
	}, [fields]);

	const changeHandler = e => {
		const f = {...fields};
		f[e.target.name] = e.target.value;
		setFields(f);
	};

	const saveCompanyInformation = e => {
		e.preventDefault();
		setNeedValid(true);
		setLockForm(true);
		setLoading(true);
		if (!errors.length) {
			setUserUpdate(fields)
				.then(() => {
					setLockForm(false);
					setLoading(false);
				});
		}
	};

	return (
		<form onSubmit={saveCompanyInformation}>

			<div className="form-settings__company overall-design">

				<SettingTitle>
					Company Info
				</SettingTitle>
				{loading && <Spinner overlay />}
				<div className="form-settings__wrapper">
					<div className="row">
						<div className="col6">
							<TextField
								id="companyName"
								type="text"
								fieldName="Company name"
								value={fields['companyName']}
								errors={errors}
								onChange={changeHandler}
								disabled={lockForm}
							/>
							<TextField
								id="position"
								type="text"
								fieldName="Position"
								value={fields['position']}
								errors={errors}
								onChange={changeHandler}
								disabled={lockForm}
							/>
							<TextField
								id="companyAddress"
								type="text"
								fieldName="Company address"
								value={fields['companyAddress']}
								errors={errors}
								onChange={changeHandler}
								disabled={lockForm}
							/>
							<TextField
								id="city"
								type="text"
								fieldName="City"
								value={fields['city']}
								errors={errors}
								onChange={changeHandler}
								disabled={lockForm}
							/>
						</div>
						<div className="col6">
							<SelectField
								id="state"
								fieldName="State"
								value={fields['state']}
								options={STATES}
								errors={errors}
								onChange={changeHandler}
								disabled={lockForm}
							/>
							<TextField
								id="zipCode"
								type="text"
								fieldName="Zip code"
								value={fields['zipCode']}
								errors={errors}
								onChange={changeHandler}
								disabled={lockForm}
							/>
							<TextField
								id="phone"
								type="text"
								fieldName="Phone"
								value={fields['phone']}
								errors={errors}
								onChange={changeHandler}
								disabled={lockForm}
								mask="+1 (999) 999-99-99"
							/>
							<TextField
								id="ein"
								type="text"
								fieldName="EIN"
								value={fields['ein']}
								errors={errors}
								onChange={changeHandler}
								disabled={lockForm}
								mask="99-9999999"
							/>

						</div>
					</div>
					<div className="button_rigth">
						<Button type="submit" color="green" width="width">Save</Button>
					</div>
				</div>




			</div>
		</form>
	);
};


CompanyInformation.propTypes = {
	user: PropTypes.any
};

export default CompanyInformation;
import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../../utils/classNames';
import './filefield.scss';


const FileField = ({id, fieldName, file, onChange, errors}) => {
  

  // const onFocus = () => {
  //   setFilled(true);
  // };
  // const onBlur = () => {
  //   setFilled(value ? true : false);
  // };

  return (
    <div className={classes(
      'formfield filefield',
      errors[id] && 'error',
      file && 'filled',
    )}>
      <label
        htmlFor={id}
        className="filefield__label"
      >
        <span className="filefield__filename">
          {file ? file.name : fieldName}
        </span>
        <span className="filefield__button">
          Choose file
        </span>
      </label>
      <span className="filefield__label-bg" >{fieldName}</span>
      <input
        id={id}
        name={id}
        type="file"
        onChange={onChange}
      />
      <span className="error">{errors[id]}</span>
    </div>
  );
};

FileField.propTypes = {
  id: PropTypes.string,
  fieldName: PropTypes.string,
  file: PropTypes.any,
  onChange: PropTypes.any,
  errors: PropTypes.array
};


export default FileField;
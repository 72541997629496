export const validHandler = (fields, validation = []) => {
  let valid = true;
  let errors = [];

  for (const field of Object.entries(fields)) {
    const name = field[0];
    const value = field[1];
    if (validation.indexOf(name) != -1) {
      if (value == '') {
        errors[name] = 'This field is required';
        valid = false;
      }
    }
  }

  //Validateion password
  if (fields.password && validation.indexOf('repeatPassword') != -1 ) {
    if (fields.password != fields.repeatPassword) {
      errors['password'] = 'Passwords did not match';
      errors['repeatPassword'] = 'Passwords did not match';
      valid = false;
    }
    const re = /[0-9]/;
    if (!re.test(fields.password)) {
      errors['password'] = 'Password must contain at least one number (0-9)';
      valid = false;
    }
    const re1 = /\W/;
    if (!re1.test(fields.password)) {
      errors['password'] = 'Password must contain at least one special character.';
      valid = false;
    }
    const re2 = /[A-Z]/;
    if (!re2.test(fields.password)) {
      errors['password'] = 'Password must contain at least one capital letter.';
      valid = false;
    }
    if (fields.password.length < 8) {
      errors['password'] = 'Password length must be atleast 8 characters';
      valid = false;
    }
  }

  //Validateion phone
  if (fields.phone && validation.indexOf('phone') != -1) {
    if (fields.phone.replaceAll('_', '').length < 18) {
      
      errors['phone'] = 'The phone must be completely full';
      valid = false;
    }
  }

  //Validateion EIN
  if (fields.ein && validation.indexOf('ein') != -1) {
    if (fields.ein.replaceAll('_', '').length < 10) {
      errors['ein'] = 'The EIN must be completely full';
      valid = false;
    }
  }

  //Validateion licenseNumber
  if (fields.licenseNumber && validation.indexOf('licenseNumber') != -1) {
    if (fields.licenseNumber.replaceAll('_', '').length < 15) {
      errors['licenseNumber'] = 'The licenseNumber must be completely full';
      valid = false;
    }
  }


  //Validateion issueDate
  if (fields.issueDate && validation.indexOf('issueDate') != -1) {
    if (fields.issueDate.replaceAll('_', '').length < 10 || fields.issueDate.replaceAll('_', '') == 'Invalid Date') {
      errors['issueDate'] = 'The issue Date must be completely full';
      valid = false;
    }
  }

  //Validateion expirationDate
  if (fields.expirationDate && validation.indexOf('expirationDate') != -1) {
    if (fields.expirationDate.replaceAll('_', '').length < 10 || fields.expirationDate.replaceAll('_', '') == 'Invalid Date') {
      errors['expirationDate'] = 'The expiration Date must be completely full';
      valid = false;
    }
  } 

  //Validation email
  if (fields.email) {
    const reEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!reEmail.test(fields.email)) {
      errors['email'] = 'Email should be like email@example.com';
      valid = false;
    }
  }

  return {valid, errors};
};


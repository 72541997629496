import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import './formSettings.scss';
import Button from '../ui/Button';
import TextField from '../ui/TextField';
//import FileField from "../ui/FileField";
import DateField from '../ui/DateField';
import {validHandler} from '../../utils/validation';
import {getUrlForUpload, putFileOnAmazon, setUserUpdate} from '../../services/api';
import SettingTitle from '../ui/SettingTitle';
import {FileIcon, PdfIcon} from '../../assets/img/icons';
import Spinner from '../Spinner';

import {userGet} from '../../redux/actions/userActions';


const LicenseInformation = ({user}) => {
	const dispatch = useDispatch();
	const [errors, setErrors] = useState([]);
	const [lockForm, setLockForm] = useState(false);
	const [needValid, setNeedValid] = useState(false);
	const [fields, setFields] = useState({licenseNumber: user.licenseNumber, licenseType: user.licenseType, issueDate: user.issueDate, expirationDate: user.expirationDate});
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (needValid) {
			const validation = ['email'];
			const {errors} = validHandler(fields, validation);
			setErrors(errors);
		}
	}, [fields]);

	const fileInputHandler = e => {
		e.preventDefault();
		setLoading(true);
		const onChange = async (file) => {
			try {
				let fileName;
				getUrlForUpload({
					contentType: file.type,
					fileName: file.name
				})
					.then(res => {
						fileName = res.fileName;
						return putFileOnAmazon({url: res.uploadUrl, file});
					})
					.then(() => {
						return setUserUpdate({licenseFile: fileName});
					})
					.then(() => {
						return dispatch(userGet());
					})
					.then(() => {
						setLoading(false);
					});
			} catch (err) {
				console.log(err);
			}
		};
		onChange(e.target.files[0]);
	};

	const changeHandler = e => {
		const f = {...fields};
		f[e.target.name] = e.target.value;
		setFields(f);
	};

	const changeDateHandler = (name, value) => {
		const f = {...fields};
		f[name] = value;
		setFields(f);
	};

	const saveCompanyInformation = e => {
		e.preventDefault();
		setNeedValid(true);
		setLockForm(true);
		setLoading(true);
		if (!errors.length) {
			setUserUpdate(fields)
				.then(() => {
					setLockForm(false);
					setLoading(false);
				});
		}
	};

	return (
		<div className="form-settings__license overall-design">
			<SettingTitle>
				License Info
			</SettingTitle>
			{loading && <Spinner overlay />}
			<div className="form-settings__wrapper">
				<form onSubmit={saveCompanyInformation}>

					<div className="row">
						<div className="col6">
							<TextField
								id="licenseNumber"
								type="text"
								fieldName="License number"
								value={fields['licenseNumber']}
								errors={errors}
								onChange={changeHandler}
								disabled={lockForm}
								mask="a99-9999999-LIC"
							/>
							<TextField
								id="licenseType"
								type="text"
								fieldName="License type"
								value={fields['licenseType']}
								errors={errors}
								onChange={changeHandler}
								disabled={lockForm}
							/>
						</div>
						<div className="col6">
							<DateField
								id="issueDate"
								fieldName="Issue date"
								value={fields['issueDate']}
								errors={errors}
								onChange={changeDateHandler}
								disabled={lockForm}
								mask="99/99/9999"
							/>
							<DateField
								id="expirationDate"
								fieldName="Expiration date"
								value={fields['expirationDate']}
								errors={errors}
								onChange={changeDateHandler}
								disabled={lockForm}
								mask="99/99/9999"
							/>
						</div>
					</div>
					<div className="input-file">
						<input type="file" id="licenseFile" onChange={fileInputHandler} />
						<label htmlFor="licenseFile">
							<div className="input-file__title">License file</div>
							<div className="input-file__text">
								<div className="input-file__img">
									{user.licenseFile ? <PdfIcon /> : <FileIcon />}
									<div className="input-file__name">
										{user.licenseFile &&
											<a
												href={user.licenseFile}
												target="_blank"
												rel="noreferrer"
											>
												{user.licenseFile.split('/')[4]}
											</a>
										}
									</div>
								</div>
								Choose license file
							</div>
						</label>
					</div>
					<div className="button_rigth">
						<Button type="submit" color="green" width="width">Save Changes</Button>
					</div>
				</form>
			</div>
		</div>
	);
};


LicenseInformation.propTypes = {
	user: PropTypes.any
};

export default LicenseInformation;
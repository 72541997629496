import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import TableItem from '../../components/TableItem';
import {dateFormat} from '../../utils/format';
import Status from './Status';


const InvoicesItem = ({item}) =>
  <TableItem key={item.id}>
 
    <Link to={`/invoice/${item.id}`}>{item.number}</Link>
 
    {dateFormat(item.createAt)}

    {item.quantity}

    <>$ {item.summ.toLocaleString('en-US')}</>

    <Status status={item.status} />

  </TableItem>;

InvoicesItem.propTypes = {
  item: PropTypes.any
};

export default InvoicesItem;

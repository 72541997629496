import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  Container,
} from 'reactstrap';
import FormSettings from '../../components/FormSettings';

import './settings.scss';

const Settings = () => {
  const history = useHistory();
  const user = useSelector(state => state.user.user);
  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      history.push('/sign-in');
    }
  }, []);
  return (
    <Container fluid className="p-0">
      <div className="block_top">
        <div className="block_right">
          <div>
            <h1>Settings</h1>
            <p className="subtitle">Change your data</p>
          </div>
        </div>
      </div>

      <FormSettings user={user}/>

    </Container>
  );
};

export default Settings;

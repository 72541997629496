import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Container} from 'reactstrap';
import SetMetrcApiKey from './SetMetrcApiKey';
import Button from '../../components/ui/Button';
import AlertSmall from '../../components/AlertSmall';
import './inventory.scss';
import Inventory from '../../components/Inventory';

const InventoryPage = () => {
  const user = useSelector(state => state.user.user);
  const [showAlertSuccesSync, setShowAlertSuccesSync] = useState(localStorage.getItem('showAlertSuccesSync') ? localStorage.getItem('showAlertSuccesSync') : false);

  const onHideAlert = () => {
    setShowAlertSuccesSync(false);
    localStorage.removeItem('showAlertSuccesSync');
  };

  return (
    <Container fluid className="p-0 inventory">
      <div className="block_top inventory__top">
        <div className="block_right">
          <div>
            <h1>Inventory</h1>
            <p className="subtitle">Add your products to the marketplace</p>
          </div>
          {showAlertSuccesSync &&
            <AlertSmall
              title="Your inventory has successfully synced"
              onHide={onHideAlert}
            />
          }
        </div>
        {user.metrcApiKey && user.loadFromMetrcComplete < 100 &&
          <Button color="green" type="button" width="width">Add new product</Button>
        }
      </div>
      {user.id && user.loadFromMetrcComplete < 100 && <SetMetrcApiKey user={user} />}

      {user.metrcApiKey && !user.loadFromMetrcComplete &&
        <div className="warning" style={{marginBottom: '20px'}}>Update inventory in progress. Please wait!</div>
      }

      {user.metrcApiKey && user.loadFromMetrcComplete == 100 &&
        <Inventory />
      }
    </Container>
  );
};

export default InventoryPage;
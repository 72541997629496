import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Resizer from 'react-image-file-resizer';
import './formSettings.scss';
import Button from '../ui/Button';
import TextField from '../ui/TextField';
import {validHandler} from '../../utils/validation';
import {getUrlForUpload, putFileOnAmazon, setUserUpdate} from '../../services/api';
import CleanButton from '../ui/CleanButton';
import SettingTitle from '../ui/SettingTitle';
import {ReactComponent as DeleteIcon} from './icon/delete.svg';
import {AddMediaIcon} from '../../assets/img/icons';
import {DefaultAvatarIcon} from '../../assets/img/icons';
import {userGet} from '../../redux/actions/userActions';
import Spinner from '../Spinner';


const UserInformation = ({user}) => {
	const dispatch = useDispatch();
	const [errors, setErrors] = useState([]);
	const [lockForm, setLockForm] = useState(false);
	const [needValid, setNeedValid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [avatarLoading, setAvatarLoading] = useState(false);
	const [avatarError, setAvatarError] = useState('');
	const [fields, setFields] = useState({firstName: user.firstName, lastName: user.lastName, email: user.email});

	useEffect(() => {
		if (needValid) {
			const validation = ['email'];
			const {errors} = validHandler(fields, validation);
			setErrors(errors);
		}
	}, [fields]);

	const changeHandler = e => {
		const f = {...fields};
		f[e.target.name] = e.target.value;
		setFields(f);
	};

	const removeAvatar = () => {
		setAvatarLoading(true);
		setUserUpdate({avatar: ''})
			.then(() => {
				return dispatch(userGet());
			})
			.then(() => {
				setAvatarLoading(false);
			});
	};

	const saveUserInformation = e => {
		e.preventDefault();
		setNeedValid(true);

		setLockForm(true);
		setLoading(true);
		if (!errors.length) {
			setUserUpdate(fields)
				.then(() => {
					return dispatch(userGet());
				})
				.then(() => {
					setLockForm(false);
					setLoading(false);
				});
		}
	};

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1920,
				1080,
				'jpeg',
				100,
				0,
				(uri) => {
					resolve(uri);
				},
				'file',
			);
		});



	const fileInputHandler = e => {
		e.preventDefault();
		const file = e.target.files[0];
		if (file) {
			if (file.type.includes('jpeg') || file.type.includes('jpg') || file.type.includes('png')) {
				setAvatarLoading(true);
				setAvatarError('');
				const onChange = async (file) => {
					try {
						const resizedFile = await resizeFile(file);
						const reader = new FileReader();
						reader.onloadend = () => {
							let fileKey;
							getUrlForUpload({
								contentType: file.type,
								fileName: file.name
							})
								.then(res => {
									fileKey = res.key;
									return putFileOnAmazon({url: res.uploadUrl, file});
								})
								.then(() => {
									return setUserUpdate({avatar: fileKey});
								})
								.then(() => {
									return dispatch(userGet());
								})
								.then(() => {
									setAvatarLoading(false);
								});
						};
						reader.readAsDataURL(resizedFile);
					} catch (err) {
						console.log(err);
					}
				};

				onChange(e.target.files[0]);
			} else {
				setAvatarError('File type must be jpeg or png');
			}
		}
	};

	return (
		<form onSubmit={saveUserInformation}>
			<div className="form-settings__row">

				<div className="form-settings__user-photo overall-design">
					{avatarLoading && <Spinner overlay />}

					<div className="form-settings__user-photo__name">{fields['firstName']} {fields['lastName']}</div>
					<label className="form-settings__user-photo__img">
						<input type="file" onChange={fileInputHandler} />
						<div className="form-settings__add-avatar">
							<AddMediaIcon />Add avatar
						</div>
						{user.avatar
							? <img src={user.avatar} alt="" />
							: <div className="form-settings__default-avatar"><DefaultAvatarIcon /></div>
						}

					</label>
					{avatarError && <div className="error">{avatarError}</div>}
					{user.avatar &&
						<CleanButton
							icon={DeleteIcon}
							onClick={removeAvatar}
							color="red"
						>
							Delete
						</CleanButton>
					}
				</div>
				<div className="form-settings__user-data overall-design">
					<SettingTitle>
						User’s Info
					</SettingTitle>
					{loading && <Spinner overlay />}
					<div className="form-settings__user-data__wrapper">
						<TextField
							id="firstName"
							type="text"
							fieldName="First Name"
							value={fields['firstName']}
							errors={errors}
							onChange={changeHandler}
							disabled={lockForm}
						/>
						<TextField
							id="lastName"
							type="text"
							fieldName="Last Name"
							value={fields['lastName']}
							errors={errors}
							onChange={changeHandler}
							disabled={lockForm}
						/>
						<TextField
							id="email"
							type="email"
							fieldName="E-mail"
							value={fields['email']}
							errors={errors}
							onChange={changeHandler}
							disabled={lockForm}
						/>
						<Button type="submit" color="green" width="width">Save Changes</Button>
					</div>
				</div>
			</div>
		</form>
	);
};

UserInformation.propTypes = {
	user: PropTypes.any
};

export default UserInformation;
import React from "react";
import PropTypes from 'prop-types';
import './content.scss';

const Content = ({children}) => <main className="content">{children}</main>;

Content.propTypes = {
  children: PropTypes.any
};

export default Content;

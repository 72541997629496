import React, {useState, useEffect} from "react";
import {useLocation, Link} from 'react-router-dom';
//import {Link} from "react-router-dom";
import {Container} from 'reactstrap';
import Main from '../../components/main';
import Header from '../../components/Header';
import TextField from '../../components/ui/TextField';
import Button from '../../components/ui/Button';
import {validHandler} from '../../utils/validation';
import {resetPassword} from '../../services/api';
import PassDescription from "../../components/passDescription";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = () => {
  const [errors, setErrors] = useState([]);
  const [errorReset, setErrorReset] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [lockForm, setLockForm] = useState(false);
  const [needValid, setNeedValid] = useState(false);
  const [success, setSuccess] = useState(false);

  const query = useQuery();

  useEffect(() => {
    if (needValid) {
      const validation = ['password', 'repeatPassword'];
      const {errors} = validHandler({password, repeatPassword}, validation);
      setErrors(errors);
    }
  }, [password, repeatPassword]);

  const submitForm = e => {
    e.preventDefault();
    const {valid, errors} = validHandler({password, repeatPassword}, ['password', 'repeatPassword']);
    setErrors(errors);
    if (!valid) {
      setNeedValid(true);
    } else {
      if (query.get('code')) {
        setLockForm(true);
        resetPassword({code: query.get('code'), password})
          .then(res => {
            console.log(res);
            if (res == 400) {
              setNeedValid(false);
              setErrorReset(true);
              setPassword('');
              setRepeatPassword('');
            } else {
              setSuccess(true);
            }
          })
          .catch(e => {
            console.log(e);

          }).finally(() => {
            setLockForm(false);
          });
      }

    }
  };

  return (<div className="signin">
    <Header page="signin" />
    <Main>
      <Container>
        <h1>New password</h1>
        <p className="subtitle">Enter password and repeat password</p>
        {success && <h3>Your password has been successfully changed. <Link to="/sign-in">Please sign in</Link></h3>}
        {!success &&
          <div className="form-block">
            <form action="" onSubmit={submitForm}>
              <TextField
                id="password"
                type="password"
                fieldName="Password"
                value={password}
                errors={errors}
                disabled={lockForm}
                onChange={e => setPassword(e.target.value)}
              />
              <TextField
                id="repeatPassword"
                type="password"
                fieldName="Repeat Password"
                value={repeatPassword}
                errors={errors}
                disabled={lockForm}
                onChange={e => setRepeatPassword(e.target.value)}
              />
              <PassDescription />
              {errorReset && <span className="error">User is not found or activation code not valid</span>}
              <div className="signin__button-group">
                <Button
                  type="submit"
                  color="green"
                  disabled={lockForm}
                >
                  Set password
                </Button>
              </div>
            </form>
          </div>
        }
      </Container>
    </Main>
  </div>
  );
};

export default ResetPassword;

import React, {useState, useEffect} from 'react';
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group';
import PropTypes from 'prop-types';
import TextField from "../../../components/ui/TextField";
import SelectField from "../../../components/ui/SelectField";
import FileField from "../../../components/ui/FileField";

import {HEARABOUTUS, COMPANY_TYPES, LICENSE_DESIGNATIONS} from '../../../constants';
import PassDescription from '../../../components/passDescription';
import DateField from '../../../components/ui/DateField';
import {getStates} from '../../../services/api';

//import Checkbox from '../../../components/ui/Checkbox';

const WizardForm = ({
  submitForm,
  tab,
  fields,
  errors,
  selectHandler,
  selectStateHandler,
  licenseTypes,
  changeHandler,
  changeDateHandler,
  fileInputHandler,
  lockForm
}) => {

  const [states, setStates] = useState([]);

  useEffect(() => {
    getStates()
      .then(res => {
        console.log(res);
        setStates(res);
      });
  }, []);


  return (
    <form action="" onSubmit={submitForm}>
      <TransitionGroup>
        <CSSTransition
          key={tab}
          classNames="fade"
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
        >
          <div className="signup__tab-content">
            {tab == 1 &&
              <>
                <TextField
                  id="firstName"
                  type="text"
                  fieldName="First name"
                  value={fields['firstName']}
                  errors={errors}
                  onChange={changeHandler}
                  disabled={lockForm}
                />
                <TextField
                  id="lastName"
                  type="text"
                  fieldName="Last name"
                  value={fields['lastName']}
                  errors={errors}
                  onChange={changeHandler}
                  disabled={lockForm}
                />
                <TextField
                  id="email"
                  type="email"
                  fieldName="E-mail"
                  value={fields['email']}
                  errors={errors}
                  onChange={changeHandler}
                  disabled={lockForm}
                />
                <TextField
                  id="password"
                  type="password"
                  fieldName="Password"
                  value={fields['password']}
                  errors={errors}
                  onChange={changeHandler}
                  disabled={lockForm}
                />
                <TextField
                  id="repeatPassword"
                  type="password"
                  fieldName="Repeat password"
                  value={fields['repeatPassword']}
                  errors={errors}
                  onChange={changeHandler}
                  disabled={lockForm}
                />
                <PassDescription />
              </>
            }

            {tab == 2 &&
              <>
                <TextField
                  id="companyName"
                  type="text"
                  fieldName="Company name"
                  value={fields['companyName']}
                  errors={errors}
                  onChange={changeHandler}
                  disabled={lockForm}
                />
                <TextField
                  id="position"
                  type="text"
                  fieldName="Position"
                  value={fields['position']}
                  errors={errors}
                  onChange={changeHandler}
                  disabled={lockForm}
                />
                <div className="signup__field-group stuck">
                  <TextField
                    id="companyAddress"
                    type="text"
                    fieldName="CompanyAddress"
                    value={fields['companyAddress']}
                    errors={errors}
                    onChange={changeHandler}
                    disabled={lockForm}
                  />
                  <TextField
                    id="city"
                    type="text"
                    fieldName="Сity"
                    value={fields['city']}
                    errors={errors}
                    onChange={changeHandler}
                    disabled={lockForm}
                  />
                </div>
                <div className="signup__field-group">
                  <SelectField
                    id="state"
                    fieldName="State"
                    value={fields['state']}
                    options={states}
                    errors={errors}
                    onChange={selectStateHandler}
                    disabled={lockForm}
                  />
                  <TextField
                    id="zipCode"
                    type="text"
                    fieldName="ZipCode"
                    value={fields['zipCode']}
                    errors={errors}
                    onChange={changeHandler}
                    disabled={lockForm}
                  />
                </div>
                <TextField
                  id="phone"
                  type="text"
                  fieldName="Phone number"
                  value={fields['phone']}
                  errors={errors}
                  onChange={changeHandler}
                  disabled={lockForm}
                  mask="+1 (999) 999-99-99"
                />
                <TextField
                  id="ein"
                  type="text"
                  fieldName="EIN"
                  value={fields['ein']}
                  errors={errors}
                  onChange={changeHandler}
                  disabled={lockForm}
                  mask="99-9999999"
                />

                <SelectField
                  id="companyType"
                  isMulti
                  fieldName="Company type details"
                  multiValue={fields['companyType']}
                  options={COMPANY_TYPES}
                  errors={errors}
                  onChange={selectHandler}
                  disabled={lockForm}
                />
              </>
            }
            {tab == 3 &&
              <>
                <TextField
                  id="licenseNumber"
                  type="text"
                  fieldName="License number"
                  value={fields['licenseNumber']}
                  errors={errors}
                  onChange={changeHandler}
                  disabled={lockForm}
                  mask="a99-9999999-LIC"
                />

                <SelectField
                  id="licenseDesignation"
                  fieldName="License Designation"
                  value={fields['licenseDesignation']}
                  options={LICENSE_DESIGNATIONS}
                  errors={errors}
                  onChange={selectHandler}
                  disabled={lockForm}
                />

                <SelectField
                  id="licenseType"
                  fieldName="License type"
                  value={fields['licenseType']}
                  options={licenseTypes}
                  errors={errors}
                  onChange={selectHandler}
                  disabled={lockForm}
                />

                <DateField
                  id="issueDate"
                  fieldName="Issue date"
                  value={fields['issueDate']}
                  errors={errors}
                  onChange={changeDateHandler}
                  disabled={lockForm}
                  mask="99/99/9999"
                />
                <DateField
                  id="expirationDate"
                  fieldName="Expiration date"
                  value={fields['expirationDate']}
                  errors={errors}
                  onChange={changeDateHandler}
                  disabled={lockForm}
                  mask="99/99/9999"
                />
                <FileField
                  id="licenseFile"
                  fieldName="License file"
                  file={fields['licenseFile']}
                  errors={errors}
                  onChange={fileInputHandler}
                  disabled={lockForm}
                />


              </>
            }
            {tab == 4 &&
              <>
                <SelectField
                  id="hearAboutUs"
                  fieldName="How did you hear about us"
                  value={fields['hearAboutUs']}
                  errors={errors}
                  options={HEARABOUTUS}
                  onChange={selectHandler}
                  disabled={lockForm}
                />
                <TextField
                  id="referral"
                  type="text"
                  fieldName="Referral"
                  value={fields['referral']}
                  errors={errors}
                  onChange={changeHandler}
                  disabled={lockForm}
                />
              </>
            }
          </div>
        </CSSTransition>
      </TransitionGroup>
    </form>
  );
};

WizardForm.propTypes = {
  submitForm: PropTypes.any,
  tab: PropTypes.any,
  fields: PropTypes.any,
  errors: PropTypes.any,
  selectHandler: PropTypes.any,
  selectStateHandler: PropTypes.any,
  licenseTypes: PropTypes.array,
  changeHandler: PropTypes.any,
  changeDateHandler: PropTypes.any,
  fileInputHandler: PropTypes.any,
  lockForm: PropTypes.bool
};


export default WizardForm;
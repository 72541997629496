import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../../utils/classNames';
import './radiofield.scss';

const SelectField = ({id, radio, value, onChange, disabled, errors}) => {
  return (
    <div className={classes(
      'formfield radiofield',
      disabled && 'disabled',
      errors && errors[id] && 'error'
    )}>
      {radio && radio.map(item =>
        <div key={item} className="radiofield__item">
          <input
            type="radio"
            id={`radio_${item}`}
            name={id}
            value={item}
            onChange={onChange}
            disabled={disabled}
            checked={value === item}
          />
          <label htmlFor={`radio_${item}`}>{item}</label>
        </div>
      )}
    </div>
  );
};

SelectField.propTypes = {
  id: PropTypes.string,
  radio: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.any,
  disabled: PropTypes.boolean,
  errors: PropTypes.array
};


export default SelectField;
import React, {Children} from 'react';
import PropTypes from 'prop-types';
import 'react-image-lightbox/style.css';
import './tableItem.scss';

const TableItem = ({children}) => {
	return (
		<div className="tr">
			{Children.map(children, child => 
				<div className="td">{child}</div>
			)}
		</div>
	);
};

TableItem.propTypes = {
	children: PropTypes.any
};

export default TableItem;
import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Container} from 'reactstrap';
import useQuery from '../../utils/useQuery';
import Pagination from '../../components/Pagination';
import {getOrders} from '../../services/api';
import './invoices.scss';
import Spinner from '../../components/Spinner';
import InvoicesItem from './InvoicesItem';

const InvoicesPage = () => {
  const {pathname} = useLocation();
  const query = useQuery(useLocation);
  const history = useHistory();
  const [invoices, setInvoices] = useState([]);
  const [invoicesCount, setInvoicesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(query.get('page') ? Number(query.get('page')) : 1);
  const [pageSize, setPageSize] = useState(localStorage.getItem('pageSize') ? parseInt(localStorage.getItem('pageSize')) : 10);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      history.push('/sign-in');
    }
  }, []);

  const getAndSetInvoices = () => {
    getOrders({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize
    })
      .then(({items, totalItems}) => {
        setInvoices(items);
        setInvoicesCount(totalItems);
        if (setLoading) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append('page', currentPage);
    }

    history.push(`${pathname}?${url}`);
    setLoading(true);
    getAndSetInvoices();
  }, [currentPage, pageSize]);


  return (
    <Container fluid className="p-0 invoices">
      <div className="block_top invoices__top">
        <div className="block_right">
          <div>
            <h1>Invoices</h1>
            <p className="subtitle">Invoices</p>
          </div>
        </div>
      </div>

      <div className="invoices__header">
        <div className="invoices__filter">

        </div>
      </div>

      <div className="invoices__table">
        <div className="tr">
          <div className="th">Number</div>
          <div className="th">Date</div>
          <div className="th">Count</div>
          <div className="th">Summ</div>
          <div className="th">Status</div>
        </div>
        {loading && <Spinner overlay />}

        {invoices.map(item =>
          <InvoicesItem
            key={item.id}
            item={item}
          />
        )}
      </div>
      <Pagination
        totalCount={invoicesCount}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        onChangePageSize={e => setPageSize(Number(e.target.value))}
      />

    </Container>
  );
};

export default InvoicesPage;
import React, {useEffect} from "react";
import {Container} from 'reactstrap';
import {useHistory, useParams} from "react-router-dom";
import Inventory from "../../components/Inventory";
import './userInventory.scss';

const UsersOne = () => {
	const {id} = useParams();
	const history = useHistory();


	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			history.push('/sign-in');
		}
	}, []);

	return (
		<Container fluid className="p-0 users-one">
			<div className="block_top">
				<div className="block_right">
					<div>
						<h1>User inventory</h1>
						<p className="subtitle">User inventory</p>
					</div>
				</div>
			</div>
			{id && <Inventory userId={id} />}
		</Container>
	);
};

export default UsersOne;

import * as types from "../constants";

const initialState = {
  cartLoading: true,
  items: [],
  totalQuantity: 0,
  totalCost: 0
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.CART_LOADING:
      return {...state, cartLoading: actions.payload};
    case types.CART_PUT:
      return {
        ...state,
        items: actions.payload.items,
        totalQuantity: actions.payload.totalQuantity,
        totalCost: actions.payload.totalCost
      };
    case types.CART_GET:
    case types.CART_CHANGE:
      return {
        ...state, items: state.items.map(item => {
          if (item.id === actions.payload.id) {
            item.quantity = actions.payload.quantity;
          }
          return item;
        })
      };
    case types.CART_REMOVE:
    case types.CART_CLEAN:
    default:
      return state;
  }
}

import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Badge} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Metrckey from '../../components/Metrckey';
import routes from '../../routes/index';
import logo from '../../assets/img/logo/logotype.png';
import './sidebar.scss';
import {ROLE_ADMIN} from '../../roles';

const SidebarItem = withRouter(
  ({name, badgeColor, badgeText, Icon, location, to, indicator, access, roles}) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };  

    if (access && access != roles.find(i => i === ROLE_ADMIN)) {
      return null;
    }

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {indicator && (
            <span className="icon-wrapper-indicator">
              <span className="indicator" />
              {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
            </span>
          )}
          {!indicator && Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          {name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

const Sidebar = () => {
  const user = useSelector(state => state.user.user);



  return (
    <nav className="sidebar sidebar-sticky">
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <img src={logo} alt="Canna Source Logo" />
          </a>

          <ul className="sidebar-nav">
            {user.roles && routes.map((category, index) =>
              <React.Fragment key={index}>
                <SidebarItem
                  name={category.name}
                  to={category.path}
                  Icon={category.icon}
                  badgeColor={category.badgeColor}
                  badgeText={category.badgeText}
                  indicator={category.indicator}
                  access={category.access}
                  roles={user.roles}
                />
              </React.Fragment>
            )}
          </ul>

          {user.id && !user.metrcApiKey &&
            <Metrckey />
          }
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;

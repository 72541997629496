import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
//import {useHistory} from 'react-router-dom';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {
  dashboard as dashboardRoutes,
  auth as authRoutes,
  one
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import ScrollToTop from "../components/ScrollToTop";
import {userGet} from '../redux/actions/userActions';
import {cartGet} from '../redux/actions/cartActions';

const childRoutes = (Layout, routes) =>
  routes.map(({path, component: Component}, index) =>
    <Route
      key={index}
      path={path}
      exact
      render={props => (
        <Layout path={path}>
          <Component {...props} />
        </Layout>
      )}
    />
  );

const Routes = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      dispatch(userGet());
      dispatch(cartGet());
    }
  }, []);
  
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {childRoutes(DashboardLayout, dashboardRoutes)}
          {childRoutes(AuthLayout, authRoutes)}
          {childRoutes(DashboardLayout, one)}
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;

import React, {useState, useEffect, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import PropTypes from 'prop-types';

import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {getUrlForUpload, putFileOnAmazon, setMedia, removeMedia, sortMedia} from "../../services/api";
import {ReactComponent as FileIcon} from './icon/file.svg';
import Button from '../ui/Button';
import Lightbox from 'react-image-lightbox';
import imgResize from '../../utils/imgResizer';
import MediaItem from '../MediaItem';
import Spinner from '../Spinner';
import './mediaInventory.scss';
import classes from '../../utils/classNames';
import TextField from '../ui/TextField';

const MediaInventory = ({id, media, getAndSetInventory}) => {

  const [loadingImage, setLoadingImage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [mediaItems, setMediaItems] = useState([]);
  const [videoItems, setVideoItems] = useState([]);
  const [video, setVideo] = useState('');


  console.log(media);

  const onOpen = i => {
    setPhotoIndex(i);
    setIsOpen(true);
  };

  useEffect(() => {
    setMediaItems(media && media.filter(item => item.type === 'Amazon S3'));
    setVideoItems(media && media.filter(item => item.type === 'video'));
  }, [media]);

  const remove = mediaId => {
    console.log(mediaId);
    setLoadingImage(true);
    removeMedia(mediaId)
      .then(() => {
        getAndSetInventory(() => setLoadingImage(false));
      });
  };

  const uploadFiles = (files) => {
    Array.prototype.forEach.call(files, (file, i) => {
      const onChange = async (file) => {
        try {
          const resizedFile = await imgResize(file);
          let fileKey;
          getUrlForUpload({
            contentType: file.type,
            fileName: file.name
          })
            .then(res => {
              fileKey = res.key;
              return putFileOnAmazon({url: res.uploadUrl, file: resizedFile});
            })
            .then(() => {
              return setMedia({fileName: fileKey, inventoryId: id});
            })
            .then(() => {
              if (i >= files.length - 1) {
                getAndSetInventory(() => setLoadingImage(false));
              }
            })
            .catch(e => {
              console.error(e);
            });

        } catch (err) {
          console.log(err);
        }
      };
      onChange(file);
    });
  };

  const handleUpload = e => {
    setLoadingImage(true);
    const files = e.target.files;
    uploadFiles(files);
  };

  const onDrop = useCallback(acceptedFiles => {
    setLoadingImage(true);
    uploadFiles(acceptedFiles);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const moveMediaItems = useCallback((dragIndex, hoverIndex) => {
    const dragCard = mediaItems[dragIndex];
    const sorted = update(mediaItems, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });

    setMediaItems(sorted);
    setLoadingImage(true);
    sortMedia(sorted.map(media => media.id))
      .then(() => {
        setLoadingImage(false);
      });
  }, [mediaItems]);

  const addVideo = () => {
    setMedia({fileName: video, type: 'video', inventoryId: id});
  };

  return (
    <>
      {loadingImage && <Spinner overlay />}
      <div className="media-inventory__title">
        Product Images
      </div>
      <div className="media-inventory__row">
        <div className={classes(
          'media-inventory__upload',
          isDragActive && 'active'
        )}
          {...getRootProps()}
        >
          <input
            {...getInputProps()}
            multiple
            type="file"
            id="uploadImage"
            name="uploadImage"
            onChange={handleUpload}
          />
          <div className="media-inventory__label">
            <FileIcon />
            <div>
              Drag & Drop your file here
            </div>
            <div>
              or
            </div>
            <Button width="width">Upload File</Button>
          </div>
        </div>
        <div className="media-inventory__images">
          <DndProvider backend={HTML5Backend}>
            {mediaItems && mediaItems.length > 0 &&
              mediaItems.map((media, i) => <MediaItem
                onClick={() => onOpen(i)}
                key={media.id}
                index={i}
                id={media.id}
                text={media.preview}
                moveCard={moveMediaItems}
                remove={remove}
              />)
            }
          </DndProvider>
        </div>
        <div className="media-inventory__content">
          <div>Your Images must be:</div>
          <p>700 x 700 minimum dimensions</p>
          <p>10 MB maximum file size</p>
          <p>Avoid using logos for product images</p>
          <p>Rearrange images by draging photo</p>
        </div>
      </div>
      <div className="media-inventory__title">Video</div>
      <div className="media-inventory__video">
        {videoItems && videoItems.map(item =>
          <div
            className="media-inventory__video-item"
            key={item.file}
          >
            <a href={item.file} target="_blank" rel="noreferrer">{item.file}</a>
            <button type="button" className="button-delete" onClick={() => remove(item.id)}></button>
          </div>
        )}
      </div>

      <div className="media-inventory__wrap">

        <div className="media-inventory__input">
          <TextField
            id="video"
            type="text"
            fieldName="Video"
            value={video}
            onChange={e => setVideo(e.target.value)}
          />
        </div>
        <Button width="width" onClick={addVideo}>Add video</Button>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={mediaItems[photoIndex].file}
          nextSrc={mediaItems[(photoIndex + 1) % mediaItems.length].file}
          prevSrc={mediaItems[(photoIndex + mediaItems.length - 1) % mediaItems.length].file}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + mediaItems.length - 1) % mediaItems.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % mediaItems.length)}
        />
      )}

    </>
  );
};

MediaInventory.propTypes = {
  id: PropTypes.string,
  media: PropTypes.any,
  getAndSetInventory: PropTypes.any
};

export default MediaInventory;
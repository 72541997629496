import React, {useState, useEffect} from 'react';
import Select, {components} from 'react-select';
import PropTypes from 'prop-types';
import classes from '../../../utils/classNames';
import {ReactComponent as ArrowDownIcon} from '../../../assets/img/icons/ArrowDown.svg';
import './selectfieldSmall.scss';

const SelectField = ({id, width, fieldName, value, options, onChange, errors, disabled}) => {
  const [filled, setFilled] = useState(value ? true : false);

  useEffect(() => {
    setFilled(value ? true : false);
  }, [value]);

  useEffect(() => {
    options.map(v => {
      if (typeof v === 'string') {
        return {value: v, label: v};
      } else {
        return v;
      }
    });

  }, [options]);

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <div
      className={classes(
        'formfield selectfieldsmall',
        filled && 'filled',
        disabled && 'disabled',
        errors && errors[id] && 'error'
      )}
      style={{
        width: width && `${width}px`
      }}
    >
      <label
        htmlFor={id}
        className="selectfieldsmall__fieldname"
      >
        {fieldName}
      </label>
      <Select
        isClearable
        className="selectfieldsmall__container"
        classNamePrefix="react-select"
        components={{DropdownIndicator}}
        defaultValue={value ? options.find(i => i.value === value) : null}
        value={value ? options.find(i => i.value === value) : null}
        onChange={selected => onChange(id, selected ? selected.value : '')}
        options={options.map(v => {
          if (typeof v === 'string') {
            return {value: v, label: v};
          } else {
            return v;
          }
        })}
      />
      <span className="error">{errors && errors[id]}</span>
    </div>
  );
};

SelectField.propTypes = {
  id: PropTypes.string,
  width: PropTypes.number,
  type: PropTypes.string,
  fieldName: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.any,
  errors: PropTypes.array,
  disabled: PropTypes.bool
};

export default SelectField;
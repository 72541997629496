import * as types from "../constants";

export function cartLoading(payload) {
  return {
    type: types.CART_LOADING,
    payload
  };
}

export function cartPut(card) {
  return {
    type: types.CART_PUT,
    payload: card
  };
}

export function cartGet() {
  return {
    type: types.CART_GET
  };
}

export function addToCart(props) {
  return {
    type: types.CART_ADD,
    payload: props
  };
}

export function changeCart(props) {
  return {
    type: types.CART_CHANGE,
    payload: props
  };
}

export function removeFromCart(props) {
  return {
    type: types.CART_REMOVE,
    payload: props
  };
}

export function cleanCart(props) {
  return {
    type: types.CART_CLEAN,
    payload: props
  };
}
import React from 'react';
import PropTypes from 'prop-types';
import {PlusIcon, MinusIcon} from '../../assets/img/icons';
import './quantityChange.scss';


const QuantityChange = ({quantity = 1, setQuantity}) => {

  return (
    <div className="quantity-change">
      <button
        type="button"
        onClick={() => setQuantity(quantity === 1 ? 1 : (quantity - 1))}
        disabled={quantity === 1}
      >
        <MinusIcon />
      </button>
      <input
        type="text"
        name="quantity"
        value={quantity}
        onChange={e => setQuantity(parseInt(e.target.value) > 1 ? parseInt(e.target.value) : 1)}
      />
      <button
        type="button"
        onClick={() => setQuantity(quantity + 1)}
      >
        <PlusIcon />
      </button>
    </div>
  );
};

QuantityChange.propTypes = {
  quantity: PropTypes.number,
  setQuantity: PropTypes.any
};

export default QuantityChange;
import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import TableItem from '../../components/TableItem';

const InvoicesItem = ({item}) =>
  <TableItem key={item.id}>
    <Link to={`/inventory/${item.inventoryId}`}>{item.inventoryName}</Link>
    <>$ {item.price.toLocaleString('en-US')}</>
    {item.quantity}
    <>$ {item.summ.toLocaleString('en-US')}</>
  </TableItem>;

InvoicesItem.propTypes = {
  item: PropTypes.any
};

export default InvoicesItem;

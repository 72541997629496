import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import classes from '../../../utils/classNames';
import './textfield.scss';


const TextField = ({Icon, id, type, fieldName, value, onChange, errors, disabled, mask}) => {
  const [filled, setFilled] = useState(value ? true : false);

  useEffect(() => {
    setFilled(value ? true : false);
  }, [value]);

  const onFocus = () => {
    setFilled(true);
  };
  const onBlur = () => {
    setFilled(value ? true : false);
  };

  return (
    <div className={classes(
      'formfield textfield',
      filled && 'filled',
      errors && errors[id] && 'error',
      Icon && 'icon'
    )}>
      <span className="textfield__label-bg" >{fieldName}</span>
      <label
        htmlFor={id}
        className="textfield__fieldname"
      >{fieldName}</label>

      {mask && <InputMask
        id={id}
        name={id}
        type={type}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        value={value ? value : ''}
        disabled={disabled}
        autoComplete={type == 'password' ? 'new-password' : undefined}
        mask={mask}
      />}

      {Icon && <Icon/>}
      {!mask && <input
        id={id}
        name={id}
        type={type}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        value={value ? value : ''}
        disabled={disabled}
        autoComplete={type == 'password' ? 'new-password' : undefined}
      />}
      <span className="error">{errors && errors[id]}</span>
    </div>
  );
};

TextField.propTypes = {
  Icon: PropTypes.any,
  id: PropTypes.string,
  type: PropTypes.string,
  fieldName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.any,
  errors: PropTypes.array,
  disabled: PropTypes.bool,
  mask: PropTypes.string
};


export default TextField;
import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.scss';
import classes from '../../../utils/classNames';
const Checkbox = ({className, title, id, onChange, disabled, checked}) =>
	<div className={classes(
		'formfield checkbox',
		className)}
	>
		<input
			type="checkbox"
			id={`checkbox_${id}`}
			name={id}
			onChange={onChange}
			disabled={disabled}
			checked={checked}
		/>
		<label htmlFor={`checkbox_${id}`}>{title}</label>
	</div>;

Checkbox.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	id: PropTypes.string,
	onChange: PropTypes.any,
	disabled: PropTypes.bool,
	checked: PropTypes.bool
};

export default Checkbox;
import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../../utils/classNames';
import './cleanButton.scss';

const CleanButton = ({children, icon: Icon, onClick, color, disabled, className}) => {
  return (
    <button
		className={classes(
      'clean-button',
      color,
      className
      )}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
			<Icon />
      {children}
    </button>
  );
};

CleanButton.propTypes = {
  children: PropTypes.any,
	icon: PropTypes.any,
  onClick: PropTypes.any,
	color: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default CleanButton;
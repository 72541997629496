import React from 'react';
import './passDescription.scss';

const PassDescription = () => (
  <div className="pass-descripton">
    Password must contain at least 8 characters, 1 capital letter, 1 number and 1 special character.
  </div>
);

export default PassDescription;

import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useDrag, useDrop} from 'react-dnd';

import './mediaItem.scss';

const MediaItem = ({id, text, index, moveCard, remove, onClick}) => {
  const ref = useRef(null);
  const [{handlerId}, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{isDragging}, drag] = useDrag({
    type: 'card',
    item: () => {
      return {id, index};
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div key={id} ref={ref} className="media-item" style={{opacity}} data-handler-id={handlerId} >
      <img src={text} alt="" onClick={onClick}/>
      <button className="button-delete" onClick={() => remove(id)}></button>
    </div>
  );
};

MediaItem.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  index: PropTypes.number,
  moveCard: PropTypes.any,
  remove: PropTypes.any,
  onClick: PropTypes.any
};

export default MediaItem;
import React, {useState, useEffect} from 'react';
import {Container} from 'reactstrap';
import Main from '../../components/main';
import Header from '../../components/Header';
import TextField from '../../components/ui/TextField';
import Button from '../../components/ui/Button';
import {validHandler} from '../../utils/validation';
import {getLinkResetPassword} from '../../services/api';

const ResetPassword = () => {
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState('');
  const [lockForm, setLockForm] = useState(false);
  const [needValid, setNeedValid] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (needValid) {
      const validation = ['email'];
      const {errors} = validHandler({email}, validation);
      setErrors(errors);
    }
  }, [email]);

  const submitForm = e => {
    e.preventDefault();
    const {valid, errors} = validHandler({email}, ['email']);
    setErrors(errors);
    if (!valid) {
      setNeedValid(true);
    } else {
      setLockForm(true);
      getLinkResetPassword({email})
        .then(res => {
          console.log(res);
          setSuccess(true);
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  return (
    <div className="signin">
      <Header page="signin" />
      <Main>
        <Container>
          <h1>Reset password</h1>
          <p className="subtitle">Enter your email to reset your password</p>
          {success && <h3>We have send on e-mail a link to reset your password</h3>}
          {!success && 
            <div className="form-block">
              <form action="" onSubmit={submitForm}>
                <TextField
                  id="email"
                  type="email"
                  fieldName="E-mail"
                  value={email}
                  errors={errors}
                  disabled={lockForm}
                  onChange={e => setEmail(e.target.value)}
                />

                <div className="signin__button-group">
                  <Button
                    type="submit"
                    color="green"
                    disabled={lockForm}
                  >
                    Reset password
                  </Button>
                </div>
              </form>
            </div>
          }
        </Container>
      </Main>
    </div>
  );
};

export default ResetPassword;

import React, {useState, useEffect} from "react";
import {Container} from 'reactstrap';
// import Button from '../../components/ui/Button';
// import ContactInfo from '../../components/ContactInfo';
// import FeaturedProduct from '../../components/FeaturedProduct';


import './usersOne.scss';
import {useHistory, useParams} from "react-router-dom";
import {getUsersOne} from "../../services/api";
import Spinner from "../../components/Spinner";
import FormSettings from "../../components/FormSettings";

const UsersOne = () => {
	const {id} = useParams();
	const history = useHistory();
	const [user, setUser] = useState({});
	const [loading, setLoading] = useState(true);

	const getAndSetUser = () => {
		let unmounted = false;
		getUsersOne(id)
			.then((inventory) => {
				if (!unmounted) {
					setUser(inventory);
					setLoading(false);
				}
			});
	};

	useEffect(() => {
		getAndSetUser();
	}, [id]);

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			history.push('/sign-in');
		}
	}, []);

	return (
		<Container fluid className="p-0 users-one">
			<div className="block_top">
				<div className="block_right">
					<div>
						<h1>User</h1>
						<p className="subtitle">User info</p>
					</div>
				</div>
			</div>
			{loading && <Spinner />}
			{!loading &&
				<FormSettings user={user}/>
			}

		</Container>
	);
};

export default UsersOne;

import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Container} from 'reactstrap';
import {ROLE_ADMIN} from '../../roles';
import {getUsers} from '../../services/api';
import useQuery from '../../utils/useQuery';
import TableItem from '../../components/TableItem';
import Spinner from '../../components/Spinner';
import Pagination from '../../components/Pagination';
import {dateFormat} from '../../utils/format';

import './users.scss';
import Checkbox from '../../components/ui/Checkbox';
import {EditIcon} from '../../assets/img/icons';

const Settings = () => {
  const {pathname} = useLocation();
  const history = useHistory();
  const user = useSelector(state => state.user.user);
  const query = useQuery(useLocation);

  const [users, setUsers] = useState([]);
  const [sortBy, setSortBy] = useState('createAt');
  const [sortDir, setSortDir] = useState('DESC');
  const [usersCount, setUsersCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(query.get('page') ? Number(query.get('page')) : 1);
  const [pageSize, setPageSize] = useState(localStorage.getItem('pageSize') ? parseInt(localStorage.getItem('pageSize')) : 10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      history.push('/sign-in');
    }
  }, []);

  const getAndSetUsers = () => {
    getUsers({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      sortBy,
      sortDir
    })
      .then(({items, totalItems}) => {
        console.log(items);
        setUsers(items);
        setUsersCount(totalItems);
        if (setLoading) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    const url = new URLSearchParams();

    if (currentPage !== 1) {
      url.append('page', currentPage);
    }

    history.push(`${pathname}?${url}`);

    setLoading(true);
    getAndSetUsers();
  }, [currentPage, pageSize, sortBy, sortDir]);


  if (user.roles && !user.roles.find(i => i === ROLE_ADMIN)) {
    return (
      <Container fluid className="p-0">
        <h3>You do not have access on this page</h3>
      </Container>
    );
  }


  const onSort = (sortBy) => {
    setSortBy(sortBy);
    setSortDir(prev => prev === 'ASC' ? 'DESC' : 'ASC');
  };

  const onSelectItem = (e, id) => {
    console.log(id);
    console.log(e.target.checked);
  };

  return (
    <Container fluid className="p-0 users">
      <div className="block_top">
        <div className="block_right">
          <div>
            <h1>Users</h1>
            <p className="subtitle">Users list</p>
          </div>
        </div>
      </div>

      <div className="users__table">
        <div className="tr">
          <div className="th sortable" onClick={() => onSort('firstName')}>
            Name{' '}
            {sortBy === 'firstName' && sortDir === 'DESC' && '↑'}
            {sortBy === 'firstName' && sortDir === 'ASC' && '↓'}
          </div>
          <div className="th">Email</div>
          <div className="th">Phone</div>
          <div className="th">Date add</div>
          <div className="th">Inventory <br />All / Published</div>
          <div className="th">Status</div>
          <div className="th"></div>

        </div>

        {loading && <Spinner overlay={true} />}

        {users.map(item =>
          <TableItem key={item.id}>
            <>
              <Checkbox
                id={item.id}
                onChange={onSelectItem}
              />
              <Link to={`${location.pathname}/${item.id}`}>{item.firstName} {item.lastName}</Link>
            </>

            {item.email}

            {item.phone}

            {dateFormat(item.createAt)}

            <Link to={`${location.pathname}/${item.id}/inventory`}>
              {item.countInventory.all} / {item.countInventory.published}
            </Link>
           
            {item.active ? 'Active' : 'Inactive'}
            
            <>
              <Link to={`${location.pathname}/${item.id}`} className="users__edit-button"><EditIcon /></Link>
            </>
          </TableItem>
        )}

      </div>
      <Pagination
        totalCount={usersCount}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        onChangePageSize={e => setPageSize(Number(e.target.value))}
      />

    </Container>
  );
};

export default Settings;

import React from 'react';
import PropTypes from 'prop-types';
//import classes from '../../../utils/classNames';
import './settingTitle.scss';

const SettingTitle = ({children}) => {
  return (
		<h2 className="setting-title">
			{children}
		</h2>
  );
};

SettingTitle.propTypes = {
  children: PropTypes.any,
};

export default SettingTitle;
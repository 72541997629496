import React from 'react';
import PropTypes from 'prop-types';
import './panel.scss';

const Panel = ({children}) => {
  return (
    <div className="panel">
      {children}
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.any
};

export default Panel;
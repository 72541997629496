import React, {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import useQuery from '../../utils/useQuery';
import {getMarketplace, getCategory} from '../../services/api';
import MarketplaceGrid from '../../components/MarketplaceGrid';
import MarketplaceList from '../../components/MarketplaceList';
import Spinner from "../../components/Spinner";
import SwitchingView from "../../components/SwitchingView";
//import classes from "../../utils/classNames";
import './marketplace.scss';
import {addToCart} from '../../redux/actions/cartActions';
import {Container} from 'reactstrap';
import TextField from '../../components/ui/TextField';
import RangeSlider from '../../components/ui/RangeSlider';
import {SearchIcon} from '../../assets/img/icons';
import SelectFieldSmall from '../../components/ui/SelectFieldSmall';

const PAGE_SIZE_MARKETPLACE = 10;

const SORT_OPTIONS = [
	{value: 'ASC', label: 'Name A-Z'},
	{value: 'DESC', label: 'Name Z-A'},
];

const MarketplacePage = () => {
	const {pathname} = useLocation();
	const query = useQuery(useLocation);
	const history = useHistory();
	const params = {filter: {}};
	for (var p of query.entries()) {
		const key = p[0].slice(0, -1).split('[');
		params.filter[key[1]] = p[1];
	}
	const dispatch = useDispatch();
	const [type, setType] = useState(localStorage.getItem('viewType') ? localStorage.getItem('viewType') : 'list');
	const [sortBy, setSortBy] = useState(params.filter && params.filter.sortBy ? params.filter.sortBy : '');
	const [sortDir, setSortDir] = useState(params.filter && params.filter.sortDir ? params.filter.sortDir : '');
	const [loading, setLoading] = useState(false);
	const [marketplace, setMarketplace] = useState([]);
	const [marketplaceCount, setMarketplaceCount] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [categories, setCategories] = useState([]);
	const [extreme, setExtreme] = useState([]);

	const [subCategories, setSubCategories] = useState([]);
	console.log(subCategories);
	const [typingTimeout, setTypingTimeout] = useState(0);

	const [filter, setFilter] = useState(params.filter ? params.filter : {});
	const [search, setSearch] = useState(params.filter && params.filter.search ? params.filter.search : '');

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			history.push('/sign-in');
		}

		getCategory()
			.then(res => {
				setCategories(res);
				const selectedParent = res.find(c => c.id == params.filter['category']);
				if (selectedParent && selectedParent.parent && selectedParent.parent.id) {
					//		setParentCategory(selectedParent.parent.id);
					const sub = res.filter(c => c.parent && c.parent.id === selectedParent.parent.id);
					setSubCategories(sub);
				}
			});

	}, []);

	const getAndSetMarketplace = (setLoading = null, reset = null) => {

		console.log(reset);
		if (reset) {
			setHasMore(true);
		}

		getMarketplace({
			paginationCount: PAGE_SIZE_MARKETPLACE,
			paginationOffset: reset ? 0 : marketplace.length,
			sortBy,
			sortDir,
			filter
		})
			.then(({items, totalItems, extreme}) => {

				if (reset) {
					setMarketplace(items);
				} else {
					setMarketplace(prev => [...prev, ...items]);
				}

				setMarketplaceCount(totalItems);

				console.log(extreme);
				setExtreme(extreme);
				
				if (setLoading) {
					setLoading(false);
				}

			});
	};

	useEffect(() => {
		const url = new URLSearchParams();

		for (const f of Object.entries(filter)) {
			if (f[1]) {
				url.append(`filter[${f[0]}]`, f[1]);
			}
		}

		if (sortBy && sortDir) {
			url.append(`filter[sortBy]`, sortBy);
			url.append(`filter[sortDir]`, sortDir);
		}

		history.push(`${pathname}?${url}`);
		setLoading(true);
		getAndSetMarketplace(setLoading, true);
	}, [sortBy, sortDir, filter]);

	const onSort = (sortBy) => {
		setSortBy(sortBy);
		setSortDir(prev => prev === 'ASC' ? 'DESC' : 'ASC');
	};

	const infiniteMoreHandler = () => {
		if (marketplace.length >= marketplaceCount) {
			setHasMore(false);
		}
		getAndSetMarketplace();
	};

	useEffect(() => {
		localStorage.setItem('viewType', type);
	}, [type]);

	const addToCardHandler = (inventoryId, quantity) => {
		dispatch(
			addToCart({inventoryId, quantity})
		);
	};

	const searchHandler = e => {
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
		setSearch(e.target.value);
		setTypingTimeout(setTimeout(function () {
			const f = {...filter};
			f[e.target.name] = e.target.value;
			setFilter(f);
		}, 600));
	};


	const selectFilterHandler = (name, value) => {
		const f = {...filter};
		if (value) {
			f[name] = value;
		} else {
			delete f[name];
		}
		setFilter(f);
	};

	const changeCategoryHandler = (name, value) => {
		const f = {...filter};
		if (value) {
			f[name] = value;
		} else {
			delete f[name];
		}
		setFilter(f);
		setSubCategories(
			categories.filter(c => c.parent && c.parent.id === value)
		);
	};

	const resetFilter = () => {
		setFilter({});
		setSubCategories([]);
		setSearch('');
		history.push(pathname);
	};

	return (
		<Container fluid className="p-0">
			<div className="block_top">
				<div className="block_right">
					<div>
						<h1>Marketplace</h1>
						<p className="subtitle"></p>
					</div>
				</div>
			</div>

			<div className="marketplace marketplace__title">
				<span>Available for purchase</span>
				<div className="marketplace__line"></div>
				<SwitchingView
					type={type}
					setType={setType}
				/>
			</div>

			<div className="marketplace__header">
				<div className="marketplace__filter">
					<div className="marketplace__filter-wrapper">
						<div className="marketplace__filter-search">
							<TextField
								Icon={SearchIcon}
								id="search"
								type="text"
								fieldName="Search..."
								value={search}
								onChange={searchHandler}
							/>
						</div>
						<div className="marketplace__filter-fields">
							<SelectFieldSmall
								id="category"
								width={210}
								fieldName="Category"
								value={filter['category']}
								options={categories.filter(c => !c.parent)}
								onChange={changeCategoryHandler}
							/>
							{/* <SelectField
								id="category"
								fieldName="Category"
								value={filter['category']}
								options={subCategories}
								onChange={changeFilter}
							/> */}

							<RangeSlider
								id="price"
								fieldName="Price"
								unitsBefore="$"
								extreme={extreme.price}
								value={filter['price']}
								onChange={selectFilterHandler}
							/>
							<RangeSlider
								id="thc"
								fieldName="THC"
								unitsAfter="%"
								extreme={extreme.thc}
								value={filter['thc']}
								onChange={selectFilterHandler}
							/>
							{Object.entries(filter).length > 0 && <div className="reset" onClick={resetFilter}>Reset filter</div>}
						</div>

					</div>
					<div className="marketplace__filter-found">
						Found {marketplaceCount} items
					</div>
				</div>

				<div className="marketplace__sort">
					<SelectFieldSmall
						id="name"
						width={210}
						fieldName="Sort"
						value={sortDir}
						options={SORT_OPTIONS}
						onChange={onSort}
					/>
				</div>

			</div>


			{loading && <Spinner overlay />}
			<InfiniteScroll
				dataLength={marketplace.length}
				next={infiniteMoreHandler}
				hasMore={hasMore}
				loader={marketplace.length > 0 ? <Spinner /> : ''}
				className="marketplace__wrap"
			>
				{marketplace.map(item => type === 'list'
					? <MarketplaceList
						key={item.id}
						item={item}
						addToCard={addToCardHandler}
					/>
					: <MarketplaceGrid
						key={item.id}
						item={item}
						addToCard={addToCardHandler}
					/>
				)}
			</InfiniteScroll>

		</Container>
	);
};

export default MarketplacePage;
import React from 'react';
import PropTypes from 'prop-types';

import './spinner.sass';
import classes from '../../utils/classNames';

const Spinner = ({overlay}) => 
  <div className={
    classes(
      'spinner',
      overlay && 'overlay'
    )}>
    <div className="spinner__block">
      <div className="spinner__side"></div>
      <div className="spinner__side"></div>
      <div className="spinner__side"></div>
      <div className="spinner__side"></div>
      <div className="spinner__side"></div>
      <div className="spinner__side"></div>
      <div className="spinner__side"></div>
      <div className="spinner__side"></div>
    </div>
  </div>
;

Spinner.propTypes = {
  overlay: PropTypes.any
};

export default Spinner;


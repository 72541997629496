//import async from "../components/Async";
// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ActivateAccount from "../pages/auth/ActivateAccount";
import ResetPassword from "../pages/auth/ResetPassword";
import NewPassword from "../pages/auth/NewPassword";

// import Page404 from "../pages/auth/Page404";
// import Page500 from "../pages/auth/Page500";

// Pages
import Settings from "../pages/Settings";
import Inventory from "../pages/Inventory";
import InventoryOne from "../pages/InventoryOne";
import Marketplace from "../pages/Marketplace";
//import Sales from "../pages/pages/Sales";
import Invoices from "../pages/Invoices";
import InvoiceOnePage from "../pages/InvoiceOne/InvoiceOnePage";
//import Messages from "../pages/pages/Messages";

import MarketplaceOne from '../pages/MarketplaceOne';

//icons
import {
  HomeIcon,
  InventoryIcon,
  MarketplaceIcon,
 // MessagesIcon,
  //SalesIcon,
  InvoicesIcon,
  SettingsIcon
} from '../assets/img/icons/';

// Dashboard
//const Default = async(() => import("../pages/dashboard"));
import Default from "../pages/dashboard";
import Users from "../pages/Users";
import UsersOne from "../pages/UsersOne";
import UserInventory from "../pages/UserInventory";
import Cart from "../pages/Cart";



const dashboardRoutes = {
  path: "/",
  name: "Home",
  icon: HomeIcon,
  component: Default,
  children: null,
  containsHome: true
};

const inventoryRoutes = {
  path: "/inventory",
  name: "Inventory",
  icon: InventoryIcon,
  component: Inventory,
  children: null
};

const inventoryOneRoutes = {
  path: "/inventory/:id",
  name: "InventoryOne",
  icon: null,
  component: InventoryOne,
  children: null
};

const marketplaceRoutes = {
  path: "/marketplace",
  name: "Marketplace",
  icon: MarketplaceIcon,
  component: Marketplace,
  children: null
};

const usersRoutes = {
  access: "ROLE_ADMIN",
  path: "/users",
  name: "Users",
  icon: MarketplaceIcon,
  component: Users,
  children: null
};

const usersOneRoutes = {
  access: "ROLE_ADMIN",
  path: "/users/:id",
  name: "UsersOne",
  icon: null,
  component: UsersOne,
  children: null
};

const userInventory = {
  access: "ROLE_ADMIN",
  path: "/users/:id/inventory",
  name: "UserInventory",
  icon: null,
  component: UserInventory,
  children: null
};

// const salesRoutes = {
//   path: "/sales",
//   name: "Sales",
//   icon: SalesIcon,
//   component: Sales,
//   children: null,
//   indicator: true
// };

// const messagesRoutes = {
//   path: "/messages",
//   name: "Messages",
//   icon: MessagesIcon,
//   component: Messages,
//   children: null
// };

const invoicesRoutes = {
  path: "/invoices",
  name: "Invoices",
  icon: InvoicesIcon,
  component: Invoices,
  children: null
};

const invoiceOneRoutes = {
  path: "/invoice/:id",
  name: "Invoice",
  component: InvoiceOnePage,
  children: null
};


const settingsRoutes = {
  path: "/settings",
  name: "Settings",
  icon: SettingsIcon,
  component: Settings,
  children: null
};


const signInRoutes = {
  path: "/sign-in",
  name: "Sign In",
  icon: SettingsIcon,
  component: SignIn,
  children: null
};

const signUpRoutes = {
  path: "/sign-up",
  name: "Sign Up",
  icon: SettingsIcon,
  component: SignUp,
  children: null
};

const activateAccountRoutes = {
  path: "/activate-account",
  name: "Activate account",
  icon: null,
  component: ActivateAccount,
  children: null
};

const resetPasswordRoutes = {
  path: "/reset-password",
  name: "Reset password",
  icon: null,
  component: ResetPassword,
  children: null
};

const newPasswordRoutes = {
  path: "/new-password",
  name: "New password",
  icon: null,
  component: NewPassword,
  children: null
};

const marketplaceOne = {
  path: "/marketplace/:id",
  name: "marketplace One",
  icon: null,
  component: MarketplaceOne,
  children: null
};

const cart = {
  path: "/cart",
  name: "cart",
  icon: null,
  component: Cart,
  children: null
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  usersRoutes,
  inventoryRoutes,
  marketplaceRoutes,
  invoicesRoutes,
  settingsRoutes
];

// Auth specific routes
export const auth = [signInRoutes, signUpRoutes, activateAccountRoutes, resetPasswordRoutes, newPasswordRoutes];

// One
export const one = [marketplaceOne, inventoryOneRoutes, usersOneRoutes, userInventory, cart, invoiceOneRoutes];

// All routes
export default dashboard;

import React, {useEffect} from 'react';
import {Container} from "reactstrap";
import {Link, useLocation} from 'react-router-dom';
import Main from "../../../components/main";
import Header from "../../../components/Header";
import {activateAccount} from '../../../services/api';
import './activate.scss';

import useQuery from '../../../utils/useQuery';

const ActivateAccount = () => {
  const query = useQuery(useLocation);
  useEffect(()=> {
    activateAccount({code: query.get('code')})
      .then(res=>{
        console.log(res);
      })
      .catch(err=>{
        console.log(err);
      });
  },[]);

  return (
    <div className="signin">
      <Header page="signin" />
      <Main>
        <Container>
          <h1>Congratulations!</h1>
          <div className="form-block">
            <h3>You successfully activated your account!</h3>
            <Link to="sign-in">Login</Link>
          </div>
        </Container>
      </Main>
    </div>
  );
};

export default ActivateAccount;

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {validHandler} from '../../utils/validation';
import TextField from '../ui/TextField';
import './formInventory.scss';
import Button from '../ui/Button';
import Checkbox from '../ui/Checkbox';
import SelectField from '../ui/SelectField';
import RadioField from '../ui/RadioField';
import TextEditor from "../../components/ui/TextEditor";


import {FileIcon, PdfIcon} from '../../assets/img/icons';

import {
  setInventoryUpdate, getCategory,/*, getUrlForUpload, putFileOnAmazon*/
  getUrlForUpload,
  putFileOnAmazon
} from '../../services/api';
import Spinner from '../Spinner';
import {GROW_TYPE, STRAIN_CLASSIFICATIONS} from '../../constants';

const FormInventory = ({inventory}) => {

  const [errors, setErrors] = useState([]);
  const [lockForm, setLockForm] = useState(false);
  const [needValid, setNeedValid] = useState(false);
  const [fields, setFields] = useState(inventory);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [loading, setLoading] = useState(false);

  //const [coaName, setCoaName] = useState('');

  useEffect(() => {
    getCategory()
      .then(setCategories);
  }, []);

  useEffect(() => {
    const category = categories.find(c => c.id === fields['category']);
    setCategoryName(category ? category.name : '');

    const selected = categories.find(c => c.id == fields['category']);
    if (selected && selected.parent && selected.parent.id) {

      const f = {...fields};
      f['parentCategory'] = selected.parent.id;
      setFields(f);
      const sub = categories.filter(c => c.parent && c.parent.id === selected.parent.id);
      setSubCategories(sub);
    }

  }, [categories]);

  useEffect(() => {
    console.log(fields);
    if (needValid) {
      const validation = ['name'];
      const {errors} = validHandler(fields, validation);
      setErrors(errors);
    }

    const category = categories.find(c => c.id === fields['category']);
    setCategoryName(category ? category.name : '');

  }, [fields]);

  const changeCategoryHandler = e => {
    const f = {...fields};
    f[e.target.name] = e.target.value;
    const sub = categories.filter(c => c.parent && c.parent.id === e.target.value);
    setSubCategories(sub);
    setFields(f);
  };

  const changeHandler = e => {
    const f = {...fields};
    f[e.target.name] = e.target.value;
    setFields(f);
  };

  const changeTextHandler = (name, value) => {
    const f = {...fields};
    f[name] = value;
    console.log(f);
    setFields(f);
  };

  const checkboxHandler = e => {
    const f = {...fields};
    f[e.target.name] = e.target.checked;
    setFields(f);
  };

  const fileInputHandler = e => {
    e.preventDefault();
    setLoading(true);
    const onChange = async (file) => {
      try {
        let fileName;
        getUrlForUpload({
          contentType: file.type,
          fileName: file.name
        })
          .then(res => {
            fileName = res.fileName;
            return putFileOnAmazon({url: res.uploadUrl, file});
          })
          .then(() => {
            setInventoryUpdate({
              'id': fields.id,
              'coa': fileName
            })
              .then(res => {
                setLockForm(false);
                setLoading(false);
                console.log(res);
              });
          })
          .then(() => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      }
    };
    onChange(e.target.files[0]);
  };


  const saveInventory = e => {
    e.preventDefault();
    setNeedValid(true);
    const validation = ['name'];
    const {valid, errors} = validHandler(fields, validation);
    setErrors(errors);

    if (valid) {
      setLockForm(true);
      console.log(fields.isPublished);
      setInventoryUpdate(
        {
          'id': fields.id,
          'name': fields.name,
          'marketplaceName': fields.marketplaceName,
          'price': fields.price,
          'thc': fields.thc,
          'category': fields.category,
          'isPublished': fields.isPublished,
          'description': fields.description,
          'showQuantity': fields.showQuantity,
          'negotiable': fields.negotiable,
          'wholesalePrice': fields.wholesalePrice,
          'strainClassification': fields.strainClassification,
          'growType': fields.growType
        })
        .then(res => {
          setLockForm(false);
          console.log(res);
        });
    }
  };

  return (
    <div className="form-inventory">
      {loading && <Spinner overlay />}
      <form onSubmit={saveInventory}>
        <div className="form-inventory__wrapper">


          <Checkbox
            title="Is published"
            id="isPublished"
            onChange={checkboxHandler}
            checked={fields['isPublished']}
            disabled={lockForm}
          />

          <TextField
            id="name"
            type="text"
            fieldName="Name"
            value={fields['name']}
            errors={errors}
            onChange={changeHandler}
            disabled={lockForm}
          />

          <TextField
            id="marketplaceName"
            type="text"
            fieldName="Marketplace name"
            value={fields['marketplaceName']}
            errors={errors}
            onChange={changeHandler}
            disabled={lockForm}
          />

          <div className="row">
            <div className="col4">
              <TextField
                id="price"
                type="text"
                fieldName="Price"
                value={String(fields['price'])}
                errors={errors}
                onChange={changeHandler}
                disabled={lockForm}
              />
            </div>
            <div className="col4">
              <TextField
                id="wholesalePrice"
                type="text"
                fieldName="Wholesale Price"
                value={String(fields['wholesalePrice'])}
                errors={errors}
                onChange={changeHandler}
                disabled={lockForm}
              />
            </div>
            <div className="col4">
              <Checkbox
                title="Negotiable"
                id="negotiable"
                checked={fields['negotiable']}
                onChange={checkboxHandler}
                disabled={lockForm}
              />
            </div>
          </div>

          <div className="row">

            <div className="formfield">
              Quantity: {fields.quantity} / {fields.unitOfMeasureName}

              <Checkbox
                className="description"
                title="Show Inventory Quantity to Buyers"
                id="showQuantity"
                onChange={checkboxHandler}
                checked={fields.showQuantity}
                disabled={lockForm}
              />
              <div className="form-inventory__text">
                Allow reatailers to see this product’s quantity
              </div>
            </div>
          </div>

          <div className="row">
            <div className="formfield">
              Category name: {categoryName}
            </div>
          </div>

          <div className="row">
            <div className="col6">
              <SelectField
                id="parentCategory"
                fieldName="Product category"
                value={fields['parentCategory']}
                errors={errors}
                options={categories.filter(c => !c.parent)}
                onChange={changeCategoryHandler}
                disabled={lockForm}
              />
            </div>
            <div className="col6">
              <SelectField
                id="category"
                fieldName="Product subcategory"
                value={fields['category'] ? fields['category'] : ''}
                errors={errors}
                options={subCategories}
                onChange={changeHandler}
                disabled={fields['parentCategory'] ? lockForm : true}
              />
            </div>
          </div>

          {fields['parentCategory'] === 'ac5baee4-4056-4f36-8df1-e6f86f31ef24' &&
            <div className="row">
              <div className="col6">
                <SelectField
                  id="growType"
                  fieldName="Flower Growing Operation"
                  value={fields['growType']}
                  errors={errors}
                  options={GROW_TYPE}
                  onChange={changeHandler}
                  disabled={lockForm}
                />
              </div>
            </div>
          }

          <div className="row">
            <div className="col6">
              <SelectField
                id="sizeCategory"
                fieldName="Size Category"
                value={fields['sizeCategory'] ? fields['sizeCategory'] : ''}
                errors={errors}
                options={subCategories}
                onChange={changeHandler}
                disabled={lockForm}
              />
            </div>
          </div>

          <div className="col3">
            <TextField
              id="thc"
              type="text"
              fieldName="THC, %"
              value={String(fields['thc'])}
              errors={errors}
              onChange={changeHandler}
              disabled={lockForm}
            />
          </div>

          <div className="input-file">
            <input type="file" id="licenseFile" onChange={fileInputHandler} />
            <div className="input-file__title">Сertificate of analysis (COA)</div>
            <label htmlFor="licenseFile">
              <div className="input-file__text">
                <div className="input-file__img">
                  {fields.coa ? <PdfIcon /> : <FileIcon />}
                  <div className="input-file__name">
                    {fields.coa &&
                      <a
                        href={fields.coa}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {fields.coa.split('/')[4]}
                      </a>
                    }
                  </div>
                </div>
                Choose file COA
              </div>
            </label>
          </div>

          <div className="form-inventory__title">
            Units Of Measure
          </div>
          <div className="row">
            <div className="col3">
              <TextField
                id="thc"
                type="text"
                fieldName="1"
                value=""
                errors={errors}
                onChange={changeHandler}
                disabled={lockForm}
              />
            </div>
            <div className="col3">
              <TextField
                id="thc"
                type="text"
                fieldName="Unit"
                value=""
                errors={errors}
                onChange={changeHandler}
                disabled={lockForm}
              />
            </div>
          </div>
        </div>
        <div className="form-inventory__title">
          Strain Classification
        </div>
        <RadioField
          id="strainClassification"
          radio={STRAIN_CLASSIFICATIONS}
          value={fields['strainClassification']}
          onChange={changeHandler}
        />

        <div className="form-inventory__title">
          Description
        </div>

        <TextEditor
          id="description"
          fieldName=""
          value={fields['description']}
          onChange={changeTextHandler}
          disabled={lockForm}
        />
        <div className="form-inventory__bottom">
          <Button
            type="submit"
            color="green"
            width="width"
            disabled={lockForm}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

FormInventory.propTypes = {
  inventory: PropTypes.any
};

export default FormInventory;
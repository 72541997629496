import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {Container} from 'reactstrap';
import Button from '../../components/ui/Button';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo1 from '../../mock/logo1.svg';
import './marketplaceOne.scss';
import {useHistory, useParams} from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs";
import Spinner from "../../components/Spinner";
import {getInventoryOne} from "../../services/api";
import {AddToCartIcon} from '../../assets/img/icons';
import {ArrowRightIcon, ArrowLeftIcon} from "../../assets/img/icons";
import CircleButton from "../../components/ui/CircleButton";
import {addToCart} from "../../redux/actions/cartActions";


const SLIDER_SETTINGS = {
	className: 'product__slider',
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1
};

const SLIDER_SETTINGS2 = {
	className: 'product__slider-nav',
	dots: false,
	infinite: false,
	slidesToShow: 4,
	swipeToSlide: true,
	focusOnSelect: true
};

const MarketplaceOne = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const {id} = useParams();
	const [inventory, setInventory] = useState({});
	const [loading, setLoading] = useState(true);
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	const [slider1, setSlider1] = useState(null);
	const [slider2, setSlider2] = useState(null);

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			history.push('/sign-in');
		}
	}, []);

	const getAndSetInventory = (callback) => {
		let unmounted = false;
		getInventoryOne(id)
			.then((inventory) => {
				if (!unmounted) {
					console.log(inventory);
					setInventory(inventory);
					setLoading(false);
				}
			})
			.finally(() => {
				if (callback) {
					callback();
				}
			});
	};

	useEffect(() => {
		getAndSetInventory();
	}, [id]);

	useEffect(() => {
		setNav1(slider1);
		setNav2(slider2);
	}, [slider1, slider2]);

	const breadcrumbs = [
		{
			title: 'Marketplace',
			link: '/marketplace'
		},
		{
			title: inventory.name
		},
	];

	const addToCardHandler = () => {
		dispatch (
			addToCart({inventoryId: id, quantity: 1})
		);
	};

	return (
		<Container fluid className="p-0 product">
			<BreadCrumbs
				breadcrumbs={breadcrumbs}
			/>

			<CircleButton
				icon={ArrowLeftIcon}
				onClick={history.goBack}
			>
				Go Back
			</CircleButton>

			<div className="overall-design">
				{loading && <Spinner overlay />}
				<div className="product__main">
					<div className="product__left">

						<Slider
							{...SLIDER_SETTINGS}
							asNavFor={nav2}
							ref={slider => setSlider1(slider)}
							nextArrow={<ArrowRightIcon />}
							prevArrow={<ArrowLeftIcon />}
						>
							{inventory.media && inventory.media.map(image =>
								<img key={image.preview2} src={image.preview2} alt="" />
							)}
						</Slider>

						<Slider
							{...SLIDER_SETTINGS2}
							asNavFor={nav1}
							ref={slider => setSlider2(slider)}
							nextArrow={<ArrowRightIcon />}
							prevArrow={<ArrowLeftIcon />}
						>
							{inventory.media && inventory.media.map(image =>
								<img key={image.preview} src={image.preview} alt="" />
							)}
						</Slider>

					</div>
					<div className="product__right">
						<div className="product__company-name">
							<img src={Logo1} /> {inventory.companyName}
						</div>
						<h1 className="product__name">
							{inventory.marketplaceName ? inventory.marketplaceName : inventory.name}
						</h1>
						<div className="product__category">
							{inventory.categoryName}
						</div>

						<div className="product__price">
							Price: ${inventory.price} / {inventory.unitOfMeasureName}
						</div>
						<div className="product__caracteristics">
							<ul>
								<li>THC: {inventory.thc}%</li>
								{inventory.coa &&
									<li>COA: Yes</li>
								}
							</ul>
						</div>

						<Button width="width" color="green" onClick={addToCardHandler}>
							<AddToCartIcon />Add to Cart
						</Button>

						<div className="product__description">
							<div dangerouslySetInnerHTML={{__html: inventory.description}} />
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default MarketplaceOne;

import React from "react";
import PropTypes from 'prop-types';

//{children}
const Auth = ({children} ) => (
  <React.Fragment>
    {children} 
  </React.Fragment>
);

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;

import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {'Content-Type': 'application/json'}
});

axiosInstance.interceptors.request.use(
  config => {
    if (localStorage.getItem('access_token') != null) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(function (config) {
  return config;
}, async e => {
  const originalRequest = e.config;
  console.log(e);
  if (e.response.status === 401 && e.config && !originalRequest._isRetry) {
    originalRequest._isRetry = true;
    try {
      const data = new URLSearchParams();
      data.append('grant_type', 'refresh_token');
      data.append('refresh_token', localStorage.getItem('refresh_token'));
      const res = await axios.post(
        `${baseUrl}/requestToken`,
        data,
        {
          headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }
      );
      localStorage.setItem('access_token', res.data.access_token);
      localStorage.setItem('refresh_token', res.data.refresh_token);
      return axiosInstance.request(originalRequest);
    } catch (e) {
      console.log(e.response);
      console.log('Not autorize');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    }
  }
  throw e;
});


export const register = async props => {
  const data = new FormData();
  for (const field of Object.entries(props)) {
    data.append(field[0], field[1]);
  }

  console.log([...data]);
  try {
    const res = await axiosInstance.post(
      '/user.register',
      data,
      {
        headers: {'Content-Type': 'multipart/form-data'}
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e.response.status;
  }
};

export const activateAccount = async ({code}) => {
  try {
    const res = await axiosInstance.post(
      '/user.activateAccount',
      {
        code
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
    return e.response.status;
  }
};

export const login = async props => {
  const data = new URLSearchParams();
  data.append('grant_type', 'password');
  data.append('username', props.email);
  data.append('password', props.password);

  try {
    const res = await axiosInstance.post(
      '/requestToken',
      data,
      {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    );
    return res.data;
  } catch (e) {
    console.log(e.message);
    if (e.message == 'Network Error') {
      return 'Network Error';
    }
    return e.response.status;
  }
};

export const getCurrentUser = async () => {
  try {
    const res = await axiosInstance.get('/user.getCurrent');
    return res.data;
  } catch (e) {
    if (e.response.status == 401) {
      console.log('Требуется авторизация рефреш');
    }
  }
};

export const getUsers = async ({paginationCount, paginationOffset, sortBy, sortDir}) => {

  const params = {
    'pagination[count]': paginationCount,
    'pagination[offset]': paginationOffset
  };

  if (sortBy && sortDir) {
    params['sort[by]'] = sortBy;
    params['sort[dir]'] = sortDir;
  }

  const res = await axiosInstance.get('/user.search', {params});
  return res.data;
};


export const getUsersOne = async id => {
  const params = {
    id
  };
  const res = await axiosInstance.get('/user.searchById', {params});
  return res.data;
};


export const setUserUpdate = async (props) => {
  console.log(props);
  const res = await axiosInstance.post('/user.update', props);
  return res.data;
};



export const addMetrcApiKey = async (metrcApiKey) => {
  try {
    const res = await axiosInstance.post('/user.setMetrcApiKey', {metrcApiKey});
    return res.data;
  } catch (e) {
    if (e.response.status == 401) {
      console.log('Требуется авторизация рефреш');
    }
  }
};


export const getPackagesFromMetrc = async () => {
  try {
    const res = await axiosInstance.post('/inventory.getPackagesFromMetrc');
    return res.data;
  } catch (e) {
    if (e.response.status == 401) {
      console.log('Требуется авторизация рефреш');
    }
  }
};

export const getStatusUpdate = async () => {
  const res = await axiosInstance.get('/inventory.getStatusUpdate');
  return res.data;
};


export const getInventory = async ({userId, paginationCount, paginationOffset, sortBy, sortDir, isPublished, filter}) => {
  const params = {
    'pagination[count]': paginationCount,
    'pagination[offset]': paginationOffset
  };

  if (sortBy && sortDir) {
    params['sort[by]'] = sortBy;
    params['sort[dir]'] = sortDir;
  }

  if (isPublished) {
    params.isPublished = isPublished;
  }

  if (filter) {
    for (const f of Object.entries(filter)) {
      if (f[1]) {
        params[`filter[${f[0]}]`] = f[1];
      }
    }
  }

  if (userId) {
    params.userId = userId;
  }

  const res = await axiosInstance.get('/inventory.search', {params});
  return res.data;
};


export const getMarketplace = async ({paginationCount, paginationOffset, sortBy, sortDir, filter}) => {
  const params = {
    'pagination[count]': paginationCount,
    'pagination[offset]': paginationOffset
  };

  if (sortBy && sortDir) {
    params['sort[by]'] = sortBy;
    params['sort[dir]'] = sortDir;
  }

  if (filter) {
    for (const f of Object.entries(filter)) {
      if (f[1]) {
        params[`filter[${f[0]}]`] = f[1];
      }
    }
  }

  const res = await axiosInstance.get('/inventory.getMarketplace', {params});
  return res.data;
};

export const getInventoryOne = async inventoryId => {
  console.log(inventoryId);
  const res = await axiosInstance.get('/inventory.searchById', {params: {inventoryId}});
  return res.data;
};

export const setInventoryUpdate = async props => {
  console.log(props);
  const res = await axiosInstance.post('/inventory.update', props);
  return res.data;
};

export const archiveInventory = async props => {
  const res = await axiosInstance.post('/inventory.archive', props);
  return res.data;
};

export const setInventoryPrice = async props => {
  const res = await axiosInstance.post('/inventory.setPrice', props);
  return res.data;
};

export const setPublished = async props => {
  const res = await axiosInstance.post('/inventory.setPublished', props);
  return res.data;
};

export const setCategory = async props => {
  const res = await axiosInstance.post('/inventory.setCategory', props);
  return res.data;
};

export const getCategory = async () => {
  const res = await axiosInstance.get('/category.search');
  return res.data;
};

export const getLinkResetPassword = async ({email}) => {
  const data = {
    email
  };
  console.log(data);
  try {
    const res = await axios({
      method: 'post',
      url: `${baseUrl}/user.getLinkResetPassword`,
      headers: {'Content-Type': 'application/json'},
      data
    });
    return res;
  } catch (e) {
    console.log(e);
    return e.response.status;
  }
};

export const resetPassword = async ({code, password}) => {
  console.log(code, password);
  const data = {
    code,
    password
  };
  console.log(data);
  try {
    const res = await axios({
      method: 'post',
      url: `${baseUrl}/user.resetPassword`,
      headers: {'Content-Type': 'application/json'},
      data
    });
    return res;
  } catch (e) {
    console.log(e);
    return e.response.status;
  }
};

export const getUrlForUpload = async (props) => {
  const res = await axiosInstance.post('/media.getUrlForUpload', props);
  return res.data;
};

export const putFileOnAmazon = async ({url, file}) => {
  const res = await axios.put(
    url,
    file,
    {
      headers: {
        'Content-Type': file.type
      }
    });
  return res;
};

export const setMedia = async (props) => {
  const res = await axiosInstance.post('/media.setMedia', props);
  return res.data;
};

export const removeMedia = async id => {
  const res = await axiosInstance.post('/media.remove', {id});
  return res.data;
};

export const sortMedia = async props => {
  console.log(props);
  const res = await axiosInstance.post('/media.sort', props);
  return res.data;
};


export const getCart = async () => {
  const res = await axiosInstance.get('/cart.getCart');
  return res.data;
};

export const addToCart = async props => {
  const res = await axiosInstance.post('/cart.addToCart', props);
  return res.data;
};

export const changeCart = async props => {
  const res = await axiosInstance.post('/cart.changeCart', props);
  return res.data;
};

export const removeFromCart = async props => {
  const res = await axiosInstance.post('/cart.removeFromCart', props);
  return res.data;
};

export const cleanCart = async props => {
  const res = await axiosInstance.post('/cart.cleanCart', props);
  return res.data;
};

export const createOrder = async props => {
  const res = await axiosInstance.post('/orders.create', props);
  return res.data;
};

export const getOrders = async ({userId, paginationCount, paginationOffset}) => {
  const params = {
    'pagination[count]': paginationCount,
    'pagination[offset]': paginationOffset
  };

  if (userId) {
    params.userId = userId;
  }

  const res = await axiosInstance.get('/orders.search', {params});
  return res.data;
};

export const getOrder = async id => {
  const params = {
    id
  };
  const res = await axiosInstance.get('/orders.searchById', {params});
  return res.data;
};

export const getLicenseTypes = async state => {
  const params = {
    state
  };
  const res = await axiosInstance.get('/licenseType.search', {params});
  return res.data;
};

export const getStates = async () => {
  const res = await axiosInstance.get('/state.search');
  return res.data;
};

import React from 'react';
import {useSelector} from 'react-redux';

import {
  Navbar,
} from "reactstrap";

import './navbar.scss';

import {ReactComponent as CartIcon} from './Cart.svg';
import {Link} from 'react-router-dom';

const NavbarComponent = () => {
 
  const user = useSelector(state => state.user.user);
  const totalQuantity = useSelector(state => state.cart.totalQuantity);

  const logoutHandler = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    document.location.href = '/sign-in';
  };

  return (
    <Navbar expand>
      <div className="navbar-content">
        <ul className="navbar-menu__block">

          <li className="navbar-menu__item">
            <Link to="/cart">
              <span className="navbar-menu__cart-indicator">{totalQuantity}</span>
              <CartIcon />
            </Link>
          </li>
        </ul>
        <div className="navbar-user__block">
          <div className="navbar-user__icon">
            {user.avatar && <img src={user.avatar} alt={user.firstName + ' ' + user.lastName} title={user.firstName + ' ' + user.lastName} />}
            {!user.avatar && <div className="navbar-user_icon-dummy" />}
          </div>
          <div className="navbar-user__dropdown">
            <ul>
              <li onClick={logoutHandler}>Logout</li>
            </ul>
          </div>
        </div>
      </div>
    </Navbar >
  );
};

export default NavbarComponent;

import React from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/Button';

const Action = ({children, title, buttonText, onClickButton}) =>
  <div className="actions__card">
    <div className="actions__card-title">
      {title}
    </div>
    <div className="actions__card-description">
      {children}
    </div>
    <Button color="green" onClick={onClickButton}>{buttonText}</Button>
  </div>;

Action.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onClickButton: PropTypes.any
};

export default Action;
import React from 'react';
import PropTypes from 'prop-types';
import './alert.scss';
const Alert = ({title, text}) =>
  <div className="alert-notify">
    <div className="alert-notify__title">{title}</div>
    {text}
    <button className="alert-notify__close">&times;</button>
  </div>;

Alert.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Alert;
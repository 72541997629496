import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container} from 'reactstrap';
import CartItem from '../../components/CartItem';
import Button from '../../components/ui/Button';
import Spinner from '../../components/Spinner';
import CleanButton from '../../components/ui/CleanButton';
import Panel from '../../components/ui/Panel';
import {ReactComponent as BasketIcon} from './Basket.svg';
import {cleanCart} from '../../redux/actions/cartActions';
import './cart.scss';
import {createOrder} from '../../services/api';

const CartPage = () => {
	const dispatch = useDispatch();
	const user = useSelector(state => state.user.user);
	const cart = useSelector(state => state.cart);

	const [loading, setLoading] = useState(false);
	const [orderSuccess, setOrderSuccess] = useState(false);

	console.log(cart);

	const cleanCartHandler = () => {
		dispatch(cleanCart());
	};

	const createOrderHandler = () => {
		setLoading(true);
		createOrder({inventory: cart.items})
			.then(() => {
				setOrderSuccess(true);
				setLoading(false);
				dispatch(cleanCart());
			});
	};

	return (
		<Container fluid className="p-0">
			<div className="block_top">
				<div className="block_right">
					<div>
						<h1>Shopping Cart</h1>
						<p className="subtitle"></p>
					</div>
				</div>
			</div>

			<div className="cart">

				{(cart.cartLoading || loading) && <Spinner overlay />}

				{orderSuccess && <h3>Your order sucessfully created!</h3>}

				{cart.totalQuantity > 0 && !orderSuccess &&
					<>
						<div className="cart__left-block">

							<CleanButton
								icon={BasketIcon}
								onClick={cleanCartHandler}
							>
								Clean Cart
							</CleanButton>

							{cart.items.map(item => <CartItem key={item.id} item={item} />)}

							<div className="cart__total">
								<span>Subtotal ({cart.totalQuantity} items): </span>$ {cart.totalCost.toLocaleString('en-US')}
							</div>
						</div>
						<div className="cart__right-block">
							<Panel>
								<div className="cart__order-title">Delivery Address:</div>
								<div className="cart__order-data">  {user.companyAddress} {user.city} {user.state} {user.zipCode} </div>
							</Panel>
							<Panel>
								<div className="cart__order-title">Your Order:</div>
								<div className="cart__order-data bottom-line">
									{cart.items.map(item =>
										<div key={item.id} className="cart__order-item">
											{item.inventory.name} x {item.quantity}
										</div>
									)}
								</div>
								<div className="cart__order-subtotal">
									<span>SubTotal:</span> $ {cart.totalCost.toLocaleString('en-US')}
								</div>
								<div className="cart__order-button">
									<Button color="green" onClick={createOrderHandler}>Order Now</Button>
								</div>
							</Panel>
						</div>
					</>
				}

				{!cart.cartLoading && cart.totalQuantity == 0 && !orderSuccess &&
					<div className="cart__empty">Your cart is empty!</div>
				}

			</div>
		</Container>
	);
};

export default CartPage;

import React from 'react';
import PropTypes from 'prop-types';
import './switchingView.scss';
import classes from '../../utils/classNames';

const SwitchingView = ({type, setType}) =>
	<div className="switching-view">
		<button
			className={classes(
				'switching-view__list',
				type === 'list' && 'active'
			)}
			onClick={() => setType('list')}
		></button>
		<button
			className={classes(
				'switching-view__grid',
				type === 'grid' && 'active'
			)}
			onClick={() => setType('grid')}
		></button>
	</div>;

SwitchingView.propTypes = {
	type: PropTypes.string,
	setType: PropTypes.any
};

export default SwitchingView;
import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../../utils/classNames';
import {CheckedIcon} from './icons';

const Nav = ({children, icon, id, tab, tabValid, setTab}) => {
  const Icon = icon;
  return (
    <li className={
      classes(
        'signup__nav-item',
        tab == id && 'active'
      )
    }
      onClick={() => setTab(id)}
    >
      <span className="signup__nav-title">{children}</span>
      {tabValid[id-1] &&
        <span className="signup__nav-checked">
          <CheckedIcon />
        </span>
      }
      <span className="signup__nav-icon">
        <Icon />
      </span>
    </li>
  );
};

Nav.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.any,
  id: PropTypes.number,
  tab: PropTypes.number,
  setTab: PropTypes.any,
  tabValid: PropTypes.array
};

export default Nav;
import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../../utils/classNames';
import './circleButton.scss';

const CircleButton = ({icon:Icon, children, type, color, onClick, disabled}) => {
  return (
    <button
      className={classes(
        'circle-button',
        color,
      )}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="circle-button__icon">
        <Icon />
      </div>
      {children}
    </button>
  );
};

CircleButton.propTypes = {
  icon: PropTypes.any,
  children: PropTypes.any,
  type: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.any,
  disabled: PropTypes.bool
};

export default CircleButton;
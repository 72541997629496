import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {setInventoryPrice} from '../../services/api';
import './editablePrice.scss';
import classNames from 'classnames';

const EditablePrice = ({id, value}) => {
  const [edit, setEdit] = useState(false);
  const [load, setLoad] = useState(false);
  const [newPrice, setNewPrice] = useState(value);

  const saveData = () => {
    if (newPrice != value) {
      setLoad(true);
      setInventoryPrice({id, price: newPrice})
        .then(res => {
          console.log(res);
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const onKeyPress = e => {
    if (e && e.charCode === 13) {
      setEdit(false);
      saveData();
    }
  };

  const onBlur = () => {
    setEdit(false);
    saveData();
  };

  return (
    <>
      {edit &&
        <input
          className={classNames(
            'edit-price',
            load && 'load'
          )}
          maxLength={6}
          type="text"
          id={`price_${id}`}
          name={`price_${id}`}
          value={newPrice}
          onChange={e => setNewPrice(+e.target.value)}
          onBlur={onBlur}
          autoFocus
          onKeyPress={onKeyPress}
        />
      }
      <span
        className={classNames(
          'edit-price',
          load && 'load'
        )}
        hidden={edit}
        onClick={() => setEdit(prev => !prev)}
      >
        $ {' '}{newPrice ? newPrice.toLocaleString('en-US') : '0.00'}
      </span>
    </>
  );
};

EditablePrice.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.any,
  saveData: PropTypes.any
};

export default EditablePrice;


import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './formSettings.scss';
import Button from '../ui/Button';
import TextField from '../ui/TextField';
import {validHandler} from '../../utils/validation';
import {setUserUpdate} from '../../services/api';
import SettingTitle from '../ui/SettingTitle';
import Spinner from '../Spinner';

const PasswordChange = () => {
  const [errors, setErrors] = useState([]);

  const [lockForm, setLockForm] = useState(false);
  const [needValid, setNeedValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    password: '',
    repeatPassword: ''
  });

  useEffect(() => {
    if (needValid) {
      const validation = ['password', 'repeatPassword'];
      const {errors} = validHandler(fields, validation);
      setErrors(errors);
    }
  }, [fields]);

  const changeHandler = e => {
    const f = {...fields};
    f[e.target.name] = e.target.value;
    setFields(f);
  };

  const savePassword = e => {
    e.preventDefault();
    setNeedValid(true);
    const validation = ['password', 'repeatPassword'];
    const {valid, errors} = validHandler(fields, validation);
    setErrors(errors);

    if (valid) {
      setLockForm(true);
      setLoading(true);
      setUserUpdate(fields)
        .then(() => {
          setLockForm(false);
          setLoading(false);
        });
    }
  };

  return (
    <form onSubmit={savePassword}>

      <div className="form-settings__license overall-design">
        <SettingTitle>
          Change password
        </SettingTitle>
        {loading && <Spinner overlay />}
        <div className="form-settings__wrapper">
          <div className="row">
            <div className="col6">
              <TextField
                id="password"
                type="text"
                fieldName="Password"
                value={fields['password']}
                errors={errors}
                onChange={changeHandler}
                disabled={lockForm}
              />
            </div>
            <div className="col6">
              <TextField
                id="repeatPassword"
                type="text"
                fieldName="Repeat password"
                value={fields['repeatPassword']}
                errors={errors}
                onChange={changeHandler}
                disabled={lockForm}
              />
            </div>
          </div>
          <div className="button_rigth">
            <Button Button type="submit" color="green" width="width">Change</Button>
          </div>
        </div>
      </div>






    </form>
  );
};


PasswordChange.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onClickButton: PropTypes.any
};

export default PasswordChange;
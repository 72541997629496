import React from 'react';
import PropTypes from 'prop-types';
import './alertSmall.scss';
const AlertSmall = ({title, onHide}) =>
	<div className="alert-small">
		{title}
		<button className="alert-notify__close" onClick={onHide}>&times;</button>
	</div>;

AlertSmall.propTypes = {
  title: PropTypes.string,
	onHide: PropTypes.any
};

export default AlertSmall;
import React, {useState, useEffect, Children} from 'react';
import PropTypes from 'prop-types';
import './tabs.scss';
import classes from '../../utils/classNames';

const Tabs = ({children, tabs}) => {
  const [tab, setTab] = useState(0);

  useEffect(() => {

  }, [tab]);

  return (
    <div className="tabs">
      <ul className="tabs-nav">
        {tabs && tabs.map((t, i) =>
          <li
            key={i}
            onClick={() => setTab(i)}
            className={classes(
              i === tab && 'active'
            )}
          >
            {t}
          </li>
        )}
      </ul>
      <div className="tabs-content">
        {Children.map(children, (child, i) =>
          i == tab && <div className="tabs-pane">{child}</div>
        )}
      </div>
    </div>

  );
};

Tabs.propTypes = {
  children: PropTypes.any,
  tabs: PropTypes.array
};

export default Tabs;
import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import PropTypes from 'prop-types';
import './rangeSlider.scss';

const RangeSlider = ({id, fieldName, value, onChange, extreme, unitsBefore='', unitsAfter=''}) => {
  const {createSliderWithTooltip} = Slider;
  const Range = createSliderWithTooltip(Slider.Range);
  const data = value ? value.split(',') : [extreme ? extreme.min : 0, extreme ? extreme.max : 0];
  return (
    <div className="rangeslider">
      <label
        htmlFor={id}
        className="rangeslider__fieldname"
      >
        {fieldName}{(unitsBefore || unitsAfter) && ','} {unitsBefore}{unitsAfter}
      </label>
      <Range
        min={extreme ? extreme.min : 0}
        max={extreme ? extreme.max : 0}
        defaultValue={data}
        tipFormatter={v => `${unitsBefore} ${v} ${unitsAfter}`}
        onAfterChange={v => onChange(id, v.join())}
      />
      <div className="rangeslider__bottom">
        <div className="rangeslider__input">
          {unitsBefore} <input
            type="text"
            value={data[0]}
            onChange={e => onChange(id, [+e.target.value, data[1]].join())}
          /> {unitsAfter}
        </div>
        <div className="rangeslider__input">
          {unitsBefore} <input
            type="text"
            value={data[1]}
            onChange={e => onChange(id, [data[0], +e.target.value].join())}
          /> {unitsAfter}
        </div>
      </div>
    </div>
  );
};

RangeSlider.propTypes = {
  id: PropTypes.string,
  fieldName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.any,
  extreme: PropTypes.any,
  unitsBefore: PropTypes.string,
  unitsAfter: PropTypes.string,
};

export default RangeSlider;
import React, {useState} from 'react';
import SelectFieldSmall from '../ui/SelectFieldSmall';
import CleanButton from '../ui/CleanButton';
import {ReactComponent as DeleteIcon} from './delete.svg';
import PropTypes from 'prop-types';
import {archiveInventory, setCategory, setPublished} from '../../services/api';
import './bulkUpdates.scss';

const BulkUpdates = ({categories, selectedItems, setSelectedItems, setLoading, getAndSetInventory}) => {
  const [publishUpdate, setPublishUpdate] = useState(false);

  //const [parentCategoryUpdate, setParentCategoryUpdate] = useState('');
 // const [subCategoriesUpdate, setSubCategoriesUpdate] = useState([]);
 const [subCategory, setSubCategory] = useState('');

  console.log(subCategory);
  const archiveHandler = () => {
    if (!selectedItems.length) {
      alert('Need to select items');
      return;
    }
    const confirmArchive = confirm('Do you want to archive this items?');
    if (confirmArchive) {
      setLoading(true);
      archiveInventory({ids: selectedItems, archive: true})
        .then(() => {
          setSelectedItems([]);
          getAndSetInventory();
        });
    }
  };


  const publishHandler = value => {
    console.log(value);
    if (!selectedItems.length) {
      alert('Need to select items');
      return;
    }

    setPublishUpdate(value);
    setLoading(true);
    setPublished({
      ids: selectedItems,
      isPublished: value
    })
      .then(res => {
        console.log(res);
        getAndSetInventory();
      });

  };

  const setCategoryHandler = (name, value) => {
    if (!selectedItems.length) {
      alert('Need to select items');
      return;
    }
    setSubCategory(value);
    setLoading(true);
    setCategory({
      ids: selectedItems,
      categoryId: value
    })
      .then(res => {
        console.log(res);
        getAndSetInventory();
      });

  };

  return (
    <div className="bulk-updates">
      <CleanButton
        icon={DeleteIcon}
        onClick={archiveHandler}
      >
        Archive
      </CleanButton>

      <SelectFieldSmall
        id="statusUpdate"
        fieldName="Status"
        value={publishUpdate}
        options={[{value: 0, label: 'Unpublish'}, {value: 1, label: 'Publish'}]}
        onChange={publishHandler}
      />

      <SelectFieldSmall
        width={210}
        id="category"
        fieldName="Category"
        value={''}
        options={categories.filter(c => !c.parent)}
        onChange={setCategoryHandler}
      />

      {/* <SelectFieldSmall
        id="categoryUpdate"
        fieldName="Category"
        value={subCategory}
        options={subCategoriesUpdate}
        onChange={setCategoryHandler}
      /> */}
    </div>

  );
};

BulkUpdates.propTypes = {
  categories: PropTypes.array,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.any,
  setLoading: PropTypes.any,
  getAndSetInventory: PropTypes.any
};

export default BulkUpdates;
import React from 'react';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import {composeWithDevTools} from 'redux-devtools-extension';
import Routes from './routes/Routes';

import rootReducer from './redux/reducers/index';
import {userWatcher, loginWatcher, cardWatcher} from './redux/store/sagas';

import './assets/scss/style.scss';

const saga = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      saga
    )
  )
);

saga.run(userWatcher);
saga.run(loginWatcher);
saga.run(cardWatcher);

const App = () => {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};

export default App;

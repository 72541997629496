import React, {useState, useEffect} from "react";
import {Container} from "reactstrap";
import {useHistory, useParams} from 'react-router-dom';
import {getInventoryOne} from "../../services/api";
import Spinner from "../../components/Spinner";
import FormInventory from "../../components/FormInventory";
import BreadCrumbs from "../../components/BreadCrumbs";
import Tabs from '../../components/Tabs';
import './inventoryOne.scss';
import MediaInventory from "../../components/MediaInventory";
import CircleButton from "../../components/ui/CircleButton";
import {ArrowLeftIcon} from "../../assets/img/icons";

const InventoryOne = () => {
  const {id} = useParams();
  const history = useHistory();
  const [inventory, setInventory] = useState({});
  const [loading, setLoading] = useState(true);

  const breadcrumbs = [
    {
      title: 'Inventory',
      link: '/inventory'
    },
    {
      title: inventory.name
    },
  ];

  const getAndSetInventory = (callback) => {
    let unmounted = false;
    getInventoryOne(id)
      .then((inventory) => {
        if (!unmounted) {
          setInventory(inventory);
          setLoading(false);
        }
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  };

  useEffect(() => {
    getAndSetInventory();
  }, [id]);

  // const statusHandler = () => {

  // };

  return (
    <Container fluid className="inventory-one">
      <BreadCrumbs
        breadcrumbs={breadcrumbs}
      />

      <CircleButton
        icon={ArrowLeftIcon}
        onClick={history.goBack}
      >
        Go Back
      </CircleButton>

      <div className="block_top">
        <div className="block_right">
          <div>
            <h1>{inventory.name}</h1>
          </div>
        </div>
      </div>
      <Tabs
        tabs={['General Info', 'Media']}
      >
        <div className="overall-design">
          {loading
            ? <Spinner overlay />
            : <FormInventory
              inventory={inventory}
            />
          }
        </div>
        <div className="overall-design inventory-one__media">
          <MediaInventory
            id={id}
            media={inventory.media}
            getAndSetInventory={getAndSetInventory}
          />
        </div>
      </Tabs>
    </Container>
  );
};

export default InventoryOne;
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Container} from 'reactstrap';
import Alert from '../../components/Alert';
import Actions from '../../components/Actions';

const Default = () => {

  const history = useHistory();

  const user = useSelector(state => state.user.user);

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      history.push('/sign-in');
    }
  }, []);

  return (
    <Container fluid className="p-0">
      <div>
        <h1>Dashboard</h1>
        <p className="subtitle">Welcome to Canna Source Direct!</p>
      </div>
      {user.id && !user.metrcApiKey &&
        <Alert
          title="Ready to sell your inventory?"
          text="Click on the Inventory tab to seamlessly upload your active inventory to our platform. Link your Metric API Key to take full advantage of our services."
        />}

      <Actions />
    </Container>
  );
};

export default Default;

import Resizer from 'react-image-file-resizer';

const imgResize = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1920,
      1080,
      'jpeg',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
    );
  });

export default imgResize;
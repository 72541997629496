import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Container} from 'reactstrap';
import {getOrder} from '../../services/api';
import './invoiceOne.scss';
import Spinner from '../../components/Spinner';
import InvoiceInventoryItem from './InvoiceInventoryItem';
import {dateFormat} from '../../utils/format';
import Status from '../Invoices/Status';

const InvoiceOnePage = () => {
  const {id} = useParams();
  const history = useHistory();
  const [invoice, setInvoice] = useState({});
  const [ordersInventory, setOrdersInventory] = useState([]);
  const [summ, setSumm] = useState(0);
  const [count, setCount] = useState(0);


  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      history.push('/sign-in');
    }

    getAndSetInvoice();
  }, []);


  const getAndSetInvoice = () => {
    getOrder(id)
      .then((order) => {
        console.log(order);
        setInvoice(order);
        setOrdersInventory(order.ordersInventory);

        setCount(order.ordersInventory.reduce((acc, v) => acc + v.quantity, 0));
        setSumm(order.ordersInventory.reduce((acc, v) => acc + v.summ, 0));

        if (setLoading) {
          setLoading(false);
        }
      });
  };

  return (
    <Container fluid className="p-0 invoice">
      <div className="block_top invoice__top">
        <div className="block_right">
          <div>
            <h1>Invoice #{invoice.number} from {dateFormat(invoice.createAt)}</h1>
            <p className="subtitle"></p>
          </div>
        </div>
      </div>

      <div className="invoice__information">
        {loading && <Spinner overlay />}

        <ul>
          <li>Number: {invoice.number}</li>
          <li>Date: {dateFormat(invoice.createAt)}</li>
          <li>Status: {<Status status={invoice.status} />}</li>
        </ul>
      </div>


      <div className="invoice__table">
        <div className="tr">
          <div className="th">Name</div>
          <div className="th">Price</div>
          <div className="th">Quantity</div>
          <div className="th">Summ</div>
        </div>
        {loading && <Spinner overlay />}

        {ordersInventory && ordersInventory.map(item =>
          <InvoiceInventoryItem
            key={item.id}
            item={item}
          />
        )}

        <div className="tr">
          <div className="th">Total:</div>
          <div className="th"></div>
          <div className="th">{count}</div>
          <div className="th">$ {summ.toLocaleString('en-US')}</div>
        </div>
      </div>


    </Container>
  );
};

export default InvoiceOnePage;
import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../../utils/classNames';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import './textEditor.scss';

const TextEditor = ({id, fieldName, value, onChange, disabled}) => {
  return (
    <div className={classes(
      'formfield reach-text-editor',
      disabled && 'disabled',
    )}>
      <p>{fieldName}</p>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        disabled={disabled}
        onChange={(_, editor) => {
          const data = editor.getData();
          onChange(id, data);
        }}
      />
    </div>
  );
};

TextEditor.propTypes = {
  id: PropTypes.string,
  fieldName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.any,
  disabled: PropTypes.bool
};


export default TextEditor;
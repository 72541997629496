import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './formSettings.scss';
import Spinner from '../Spinner';
import Tabs from '../Tabs/Tabs';

import UserInformation from './UserInformation';
import CompanyInformation from './CompanyInformation';
import LicenseInformation from './LicenseInformation';
import ApiMetrcInformation from './ApiMetrcInformation';
import PasswordChange from './PasswordChange';

const FormSettings = ({user}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(user);
    if (user.email) {
      setLoading(false);
    }
  }, [user]);



  return (
    <div className="form-settings">
      {loading
        ? <Spinner />
        : <Tabs
          tabs={['User’s Info', 'Company Info', 'License Info', 'Api Metrc key', 'Password change']}
        >
          <UserInformation user={user} />
          <CompanyInformation user={user} />
          <LicenseInformation user={user} />
          <ApiMetrcInformation user={user} />
          <PasswordChange user={user} />
        </Tabs>
      }
    </div>
  );
};


FormSettings.propTypes = {
  user: PropTypes.any
};

export default FormSettings;
import {put, call, takeEvery} from 'redux-saga/effects';
import {cartLoading} from '../actions/cartActions';
import {getCurrentUser, login, getCart, addToCart, changeCart, removeFromCart, cleanCart} from '../../services/api';
import * as types from '../constants';

export function* userWatcher() {
  yield takeEvery(types.USER_GET, userWorker);
}

export function* loginWatcher() {
  yield takeEvery(types.USER_LOGIN, loginWorker);
}

export function* cardWatcher() {
  yield takeEvery(types.CART_GET, cartWorker);
  yield takeEvery(types.CART_ADD, cartAddWorker);
  yield takeEvery(types.CART_CHANGE, cartChangeWorker); 
  yield takeEvery(types.CART_REMOVE, cartRemoveWorker); 
  yield takeEvery(types.CART_CLEAN, cartCleanWorker);
}

function* loginWorker({props}) {
  try {
    const res = yield call(login, props);
    if (res != 401) {
      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('refresh_token', res.refresh_token);
      yield put({
        type: types.USER_PUT,
        payload: res.user
      });
      yield put({
        type: types.USER_IS_AUTH,
        payload: true
      });
    } else {
      //document.location.href = '/sign-in';
      console.log(res);
      yield put({
        type: types.USER_ERROR_AUTH,
        payload: true
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* userWorker() {
  try {
    const res = yield call(getCurrentUser);
    console.log(res);
    yield put({
      type: types.USER_PUT,
      payload: res
    });
    yield put({
      type: types.USER_IS_AUTH,
      payload: true
    });
    
  } catch (e) {
    console.log(e.response);
    if (e.response.status == 401) {
      document.location.href = '/sign-in';
      console.log('что то пошло не так - ' + e.response.status);
      yield put({
        type: types.USER_ERROR_AUTH,
        payload: true
      });
    }
  }
}

function* cartWorker() {
  try {
    const res = yield call(getCart);
    yield put({
      type: types.CART_PUT,
      payload: res
    });
    yield put(cartLoading(false));
  } catch (e) {
    console.log(e);
  }
  
}

function* cartAddWorker({payload}) {
  console.log(payload);
  try {
    const res = yield call(addToCart, payload);
    yield put({
      type: types.CART_PUT,
      payload: res
    });
  } catch (e) {
    console.log(e);
  }
}

function* cartChangeWorker({payload}) {
  yield put(cartLoading(true));
  try {
    const res = yield call(changeCart, payload);
    yield put({
      type: types.CART_PUT,
      payload: res
    });
    yield put(cartLoading(false));
  } catch (e) {
    console.log(e);
  }
}

function* cartRemoveWorker({payload}) {
  yield put(cartLoading(true));
  try {
    const res = yield call(removeFromCart, payload);
    yield put({
      type: types.CART_PUT,
      payload: res
    });
    yield put(cartLoading(false));
  } catch (e) {
    console.log(e);
  }
}

function* cartCleanWorker() {
  yield put(cartLoading(true));
  try {
    const res = yield call(cleanCart);
    yield put({
      type: types.CART_PUT,
      payload: res
    });
    yield put(cartLoading(false));
  } catch (e) {
    console.log(e);
  }
}

// function* cartRemoveWorker({ payload }: IRemoveCartAction): Generator<StrictEffect> {
//   yield put(cartLoading(true))
//   try {
//     yield call(removeCart, payload)
//     yield call(cartWorker)
//   } catch(e) {
//     yield put(cartLoading(true))
//     toast.error('Что то пошло не так')
//   }
// }

// function* cartAddWorker({ payload }: IAddCartAction): Generator<StrictEffect> {

//   try {
//     yield call(addCart, payload)
//     yield call(cartWorker)
//     toast.success('Товар добавлен в корзину')
//   } catch(e) {
//     toast.error('Что то пошло не так')
//   }
// }

// function* cartChangeCountWorker({ payload }: IChangeCountCartAction): Generator<StrictEffect> {
//   yield put(cartLoading(true))
//   try {
//     yield call(changeCountCart, payload)
//     yield call(cartWorker)
//   } catch(e) {
//     yield put(cartLoading(true))
//     toast.error('Что то пошло не так')
//   }
// }

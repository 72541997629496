import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import classes from '../../../utils/classNames';
import './datefield.scss';
import 'react-datepicker/dist/react-datepicker.css';
import {dateFormat} from '../../../utils/format';

const DateField = ({id, fieldName, value, onChange, errors, disabled, mask}) => {
  
  const [startDate, setStartDate] = useState(value ? new Date(value) : new Date());

  const [filled, setFilled] = useState(startDate ? true : false);

  const onFocus = () => {
    setFilled(true);
  };
  const onBlur = () => {
    setFilled(value ? true : false);
  };

  useEffect(() => {
    onChange(id, dateFormat(startDate));
  }, [startDate]);


  return (
    <div className={classes(
      'formfield datefield',
      filled && 'filled',
      errors[id] && 'error'
    )}>
      <DatePicker 
        id={id}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        selected={startDate}
        onChange={date => setStartDate(date)}
        className="datefield"
        calendarClassName="datefield"
        customInput={<InputMask type="text" mask={mask}/>}
      />
      <span className="datefield__label-bg">{fieldName}</span>
      <label
        htmlFor={id}
        className="datefield__fieldname"
      >{fieldName}</label>
      <span className="error">{errors[id]}</span>
    </div>
  );
};

DateField.propTypes = {
  id: PropTypes.string,
  fieldName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.any,
  errors: PropTypes.array,
  disabled: PropTypes.bool,
  mask: PropTypes.string
};


export default DateField;
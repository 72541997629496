import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import useModalAddImage from '../../hooks/useModalAddImage';
import {AddMediaIcon} from '../../assets/img/icons';
import 'react-image-lightbox/style.css';
import './tableMedia.scss';

const TableMedia = ({id, media, onSavedMedia}) => {
	const {openModal, ModalAddImage} = useModalAddImage(onSavedMedia);

	const [isOpen, setIsOpen] = useState(false);
	const [photoIndex, setPhotoIndex] = useState(0);

	const onOpen = i => {
		setPhotoIndex(i);
		setIsOpen(true);
	};

	return (
		<div className="table-media">
			<div className="table-media__left">
				
				<a href="#" className="table-media__link" onClick={e => openModal(e, id)}>
					<AddMediaIcon />
				</a>
				

				{media && media.slice(0, 3).map((file, i) =>
					<div key={file.preview} className="table-media__image" onClick={() => onOpen(i)}>
						<img src={file.preview} alt="image" />
					</div>
				)}

				{media && media.length > 3 && 
					<div className="table-media__image">
						+ {media.length - 3}
					</div>
				}
			</div>
			<ModalAddImage />
			{isOpen && (
				<Lightbox
					mainSrc={media[photoIndex].file}
					nextSrc={media[(photoIndex + 1) % media.length].file}
					prevSrc={media[(photoIndex + media.length - 1) % media.length].file}
					onCloseRequest={() => setIsOpen(false)}
					onMovePrevRequest={() => setPhotoIndex((photoIndex + media.length - 1) % media.length)}
					onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % media.length)}
				/>
			)}
		</div>
	);
};


TableMedia.propTypes = {
	id: PropTypes.string,
	media: PropTypes.array,
	onSavedMedia: PropTypes.any
};

export default TableMedia;
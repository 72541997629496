import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import './header.scss';
import logo from '../../assets/img/logo/logotype.svg';

const Header = ({page}) => {
  return (
    <div className="header">
      <img src={logo} alt="Canna Source Direct" />
      <div className="header__nav">
        {page == 'signin' &&
          <>
            Don’t have an account? -{' '}
            <Link to="/sign-up">Register here</Link>
          </>
        }
        {page == 'signup' &&
          <>
            Already have an account? -{' '}
            <Link to="/sign-in">Login</Link>
          </>
        }
      </div>
    </div>
  );
};

Header.propTypes = {
  page: PropTypes.string
};

export default Header;
import * as types from "../constants";

export function userPut(user) {
  return {
    type: types.USER_PUT,
    payload: user
  };
}

export function userGet() {
  return {
    type: types.USER_GET
  };
}

export function userLogin(props) {
  return {
    type: types.USER_LOGIN,
    props
  };
}

export function userErrorAuth(props) {
  return {
    type: types.USER_ERROR_AUTH,
    props
  };
}
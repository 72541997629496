import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import Report from './Report';
import './rightSidebar.scss';
import usd from '../../assets/img/flags/usd.png';
import {ArrowLeft2Icon} from '../../assets/img/icons';
import CircleButton from '../../components/ui/CircleButton';
import classes from "../../utils/classNames";

const userSettings = localStorage.getItem('userSettings') ? JSON.parse(localStorage.getItem('userSettings')) : {};

const RightSidebar = () => {
  const [collapse, setCollapse] = useState(userSettings ? userSettings.collapseSideBar : false);
  const user = useSelector(state => state.user.user);

  const collapseSidebar = () => {
    setCollapse(prev => !prev);
  };

  useEffect(() => {
    userSettings.collapseSideBar = collapse;
    localStorage.setItem('userSettings', JSON.stringify(userSettings));
  }, [collapse]);


  return (
    <div className={classes(
      'right-sidebar',
      collapse && 'hide'
    )}
    >
      <div className="right-sidebar__wrapper">
        <div className="right-sidebar__collapse-button">
          <CircleButton
            onClick={collapseSidebar}
            icon={ArrowLeft2Icon}
          >
          </CircleButton>
        </div>
        <div className="right-sidebar__user">
          <div className="right-sidebar__avatar-circle">
            {user.avatar && <img src={user.avatar} alt="" />}
            {!user.avatar && <div className="right-sidebar__avatar-circle-dummy" />}
          </div>
          <div className="right-sidebar__user-name">
            {user.firstName} {user.lastName}
          </div>
          <div className="right-sidebar__user-id">
            {user.email}
          </div>
          <div className="right-sidebar__user-balance">
            0,00
            <span><img src={usd} alt="usd" /> USD</span>
          </div>
          <Report />
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
import React, {useState, useEffect} from 'react';
import Select, {components} from 'react-select';
import PropTypes from 'prop-types';
import classes from '../../../utils/classNames';
import {ReactComponent as ArrowDownIcon} from '../../../assets/img/icons/ArrowDown.svg';
import './selectfield.scss';

const SelectField = ({id, fieldName, value, multiValue, options, onChange, isMulti, errors, disabled}) => {
  const [filled, setFilled] = useState(value || (multiValue && multiValue.length) ? true : false);

  useEffect(() => {
    setFilled(value || (multiValue && multiValue.length) ? true : false);
    console.log(multiValue);
    console.log('value', value);
  }, [multiValue, value]);

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDownIcon />
      </components.DropdownIndicator>
    );
  };

  const onMenuOpen = () => {
    setFilled(true);
  };

  const onMenuClose = () => {
    setFilled(value || (multiValue && multiValue.length) ? true : false);
  };

  let defaultValue;
  if (isMulti) {
    defaultValue = multiValue ? multiValue.map(v => options.find(i => i.value === v) || null) : [];
  } else {
    defaultValue = value ? options.find(i => i.value === value) : null;
  }
  return (
    <div className={classes(
      'formfield selectfield',
      filled && 'filled',
      disabled && 'disabled',
      errors && errors[id] && 'error'
    )}>

      {isMulti
        ? <Select
          placeholder={''}
          isMulti={true}
          closeMenuOnSelect={isMulti ? false : true}
          menuPlacement="auto"
          isClearable
          components={{DropdownIndicator}}
          className="selectfield__container"
          classNamePrefix="react-select"
          defaultValue={defaultValue}
          value={defaultValue}
          onChange={selected => {
            console.log(selected);
            onChange(id, selected ? selected.map(v => v.value) : []);
          }}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          options={options}
        />
        : <Select
          placeholder={''}
          closeMenuOnSelect={isMulti ? false : true}
          menuPlacement="auto"
          isClearable
          components={{DropdownIndicator}}
          className="selectfield__container"
          classNamePrefix="react-select"
          defaultValue={defaultValue}
          value={defaultValue}
          onChange={selected => {
            console.log(selected);
            onChange(id, selected ? selected.value : '');
          }}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          options={options}
        />
      }

      <span className="selectfield__label-bg" >{fieldName}</span>
      <label
        htmlFor={id}
        className="selectfield__fieldname"
      >{fieldName}</label>
      <span className="error">{errors && errors[id]}</span>
    </div>
  );
};

SelectField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  fieldName: PropTypes.string,
  value: PropTypes.string,
  multiValue: PropTypes.array,
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  onChange: PropTypes.any,
  errors: PropTypes.array,
  disabled: PropTypes.bool
};

export default SelectField;
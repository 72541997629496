import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './marketplaceList.scss';
import {AddToCartIcon, EditIcon, Img2Icon} from '../../assets/img/icons';
import {ReactComponent as NoPhotoIcon} from './icon/NoPhoto.svg';
import classes from '../../utils/classNames';
import CleanButton from '../ui/CleanButton';

const MarketplaceList = ({item, addToCard}) => {

	return (
		<div data-id={item.id} className="marketplace-row">
			<Link to={`marketplace/${item.id}`} className="marketplace-row__images">
				{!item.media &&
					<span className="marketplace-row__nophoto">
						<NoPhotoIcon />
						No Photo
					</span>
				}
				{item.media && item.media.slice(0, 3).map((file, i) =>
					<div key={file.preview} className={
						classes(
							'marketplace-row__image',
							item.media.length > 3 && i == 2 && 'more'
						)
					}
					>
						<img src={file.preview} alt="image" />
						{item.media.length > 3 && i == 2 && <span>+{item.media.length - 3} <Img2Icon /></span>}
					</div>
				)}

			</Link>
			<div className="marketplace-row__names">
				<Link to={`marketplace/${item.id}`}>
					{item.name}
				</Link>
			</div>
			<div className="marketplace-row__units">
				<Link to={`marketplace/${item.id}`}>
					<span>{item.quantity} {item.unitOfMeasureName}</span>
				</Link>
			</div>

			<div className="marketplace-row__characteristics">
				<Link to={`marketplace/${item.id}`}>
					<span>THC: {item.thc}%</span>
				</Link>
			</div>

			<div className="marketplace-row__price">
				$ {item.price}
			</div>


			<div className="marketplace-row__actions">

				<CleanButton
					icon={AddToCartIcon}
					className="marketplace-row__card"
					onClick={() => addToCard(item.id, 1)}
				/>

				<Link to={`/inventory/${item.id}`} className="marketplace-row__edit">
					<EditIcon />
				</Link>
			</div>
		</div>
	);
};

MarketplaceList.propTypes = {
	item: PropTypes.any,
	addToCard: PropTypes.any
};

export default MarketplaceList;
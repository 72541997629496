import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';
import {getCategory, getInventory, setPublished} from '../../services/api';
import {SearchIcon} from '../../assets/img/icons/index';
import Spinner from '../Spinner';
import './inventory.scss';
import useQuery from '../../utils/useQuery';
import Pagination from '../Pagination';
import SelectFieldSmall from '../ui/SelectFieldSmall';
import Checkbox from '../ui/Checkbox';
import TextField from '../ui/TextField';
import BulkUpdates from '../BulkUpdates';
import InventoryItem from './InventoryItem';

const Inventory = ({userId}) => {
	const {pathname} = useLocation();
	const query = useQuery(useLocation);
	const history = useHistory();
	const params = {filter: {}};
	for (var p of query.entries()) {
		const key = p[0].slice(0, -1).split('[');
		params.filter[key[1]] = p[1];
	}
	const [inventory, setInventory] = useState([]);
	const [sortBy, setSortBy] = useState('name');
	const [sortDir, setSortDir] = useState('ASC');
	const [inventoryCount, setInventoryCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(query.get('page') ? Number(query.get('page')) : 1);
	const [pageSize, setPageSize] = useState(localStorage.getItem('pageSize') ? parseInt(localStorage.getItem('pageSize')) : 10);
	const [loading, setLoading] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	console.log(subCategories);

	const [typingTimeout, setTypingTimeout] = useState(0);

	const [filter, setFilter] = useState(params.filter ? params.filter : {});
	const [search, setSearch] = useState(params.filter && params.filter.search ? params.filter.search : '');

	useEffect(() => {
		getCategory()
			.then(res => {
				setCategories(res);
				const selectedParent = res.find(c => c.id == params.filter['category']);
				if (selectedParent && selectedParent.parent && selectedParent.parent.id) {
					//		setParentCategory(selectedParent.parent.id);
					const sub = res.filter(c => c.parent && c.parent.id === selectedParent.parent.id);
					setSubCategories(sub);
				}
			});
	}, []);


	const getAndSetInventory = () => {
		getInventory({
			userId,
			paginationCount: pageSize,
			paginationOffset: (currentPage - 1) * pageSize,
			sortBy,
			sortDir,
			filter
		})
			.then(({items, totalItems}) => {
				setInventory(items);
				setInventoryCount(totalItems);
				if (setLoading) {
					setLoading(false);
				}
			});
	};

	const onSavedMedia = () => {
		setLoading(true);
		getAndSetInventory();
	};

	useEffect(() => {
		localStorage.setItem('pageSize', pageSize);
	}, [pageSize]);

	useEffect(() => {
		const url = new URLSearchParams();
		if (currentPage !== 1) {
			url.append('page', currentPage);
		}

		for (const f of Object.entries(filter)) {
			if (f[1]) {
				url.append(`filter[${f[0]}]`, f[1]);
			}
		}

		history.push(`${pathname}?${url}`);
		setLoading(true);
		getAndSetInventory();
		setSelectedItems([]);
	}, [currentPage, pageSize, sortBy, sortDir, filter]);

	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			history.push('/sign-in');
		}
	}, []);

	const onSelectItem = (e, id) => {
		if (e.target.checked) {
			setSelectedItems(prev => [...prev, id]);
		} else {
			setSelectedItems(prev => prev.filter(i => i !== id));
		}
	};

	const onSelectAll = e => {
		if (e.target.checked) {
			setSelectedItems(inventory.map(item => item.id));
		} else {
			setSelectedItems([]);
		}
	};

	const setInventoryPublished = (id, isPublished) => {
		setPublished({
			ids: [id],
			isPublished: !isPublished
		})
			.then(() => {
				getAndSetInventory();
			});
	};

	const onSort = (sortBy) => {
		setSortBy(sortBy);
		setSortDir(prev => prev === 'ASC' ? 'DESC' : 'ASC');
	};

	const selectFilterHandler = (name, value) => {
		const f = {...filter};
		if (value) {
			f[name] = value;
		} else {
			delete f[name];
		}

		setFilter(f);
	};

	const changeCategoryHandler = (name, value) => {
		const f = {...filter};
		if (value) {
			f[name] = value;
		} else {
			delete f[name];
		}
		setFilter(f);
		setSubCategories(
			categories.filter(c => c.parent && c.parent.id === value)
		);
	};

	const searchHandler = e => {
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
		setSearch(e.target.value);
		setTypingTimeout(setTimeout(function () {
			const f = {...filter};
			f[e.target.name] = e.target.value;
			setFilter(f);
		}, 600));
	};

	const resetFilter = () => {
		setFilter({});
		setSubCategories([]);
		setSearch('');
		history.push(pathname);
	};

	return (
		<>
			<div className="inventory__header">
				<div className="inventory__filter">
					<div className="inventory__filter-wrapper">
						<div className="inventory__filter-search">
							<TextField
								Icon={SearchIcon}
								id="search"
								type="text"
								fieldName="Search..."
								value={search}
								onChange={searchHandler}
							/>
						</div>
						<div className="inventory__filter-fields">
							<SelectFieldSmall
								id="status"
								width={140}
								fieldName="Status"
								value={filter['status']}
								options={[{value: '1', label: 'Published'}, {value: '0', label: 'Unpublished'}]}
								onChange={selectFilterHandler}
							/>
							<SelectFieldSmall
								id="category"
								width={210}
								fieldName="Category"
								value={filter['category']}
								options={categories.filter(c => !c.parent)}
								onChange={changeCategoryHandler}
							/>
							{/* <SelectField
								id="category"
								fieldName="Category"
								value={filter['category']}
								options={subCategories}
								onChange={changeFilter}
							/> */}
							{Object.entries(filter).length > 0 && <div className="reset" onClick={resetFilter}>Reset filter</div>}
						</div>

					</div>
					<div className="inventory__filter-found">
						Found {inventoryCount} items
					</div>
				</div>
				{selectedItems.length > 0 &&
					<BulkUpdates
						categories={categories}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						setLoading={setLoading}
						setInventoryPublished={setInventoryPublished}
						getAndSetInventory={getAndSetInventory}
					/>
				}
			</div>

			<div className="inventory__table">
				<div className="tr">
					<div className="th sortable" >
						<Checkbox
							id="check_all"
							checked={selectedItems.length > 0}
							onChange={onSelectAll}
						/>
						<span onClick={() => onSort('name')}>
							Product{' '}
							{sortBy === 'name' && sortDir === 'DESC' && '↑'}
							{sortBy === 'name' && sortDir === 'ASC' && '↓'}
						</span>
					</div>
					<div className="th">Price</div>
					<div className="th">Quantity</div>
					<div className="th">Category</div>
					<div className="th">Media</div>

				</div>
				{loading && <Spinner overlay={true} />}

				{inventory.map(item =>
					<InventoryItem
						key={item.id}
						item={item}
						selectedItems={selectedItems}
						onSelectItem={onSelectItem}
						onSavedMedia={onSavedMedia}
						setInventoryPublished={setInventoryPublished}
					/>
				)}
			</div>
			<Pagination
				totalCount={inventoryCount}
				currentPage={currentPage}
				pageSize={pageSize}
				setCurrentPage={setCurrentPage}
				onChangePageSize={e => setPageSize(Number(e.target.value))}
			/>
		</>
	);
};

Inventory.propTypes = {
	userId: PropTypes.string
};

export default Inventory;
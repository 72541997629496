import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import TextField from '../../components/ui/TextField';
import Button from '../../components/ui/Button';
import {validHandler} from '../../utils/validation';
import {addMetrcApiKey, getPackagesFromMetrc, getStatusUpdate} from '../../services/api';
const validation = ['metrcApiKey'];



const SetMetrcApiKey = ({user}) => {
  const [errors, setErrors] = useState([]);
  const [lockForm, setLockForm] = useState(false);
  const [needValid, setNeedValid] = useState(false);
  const [processUpdate, setProcessUpdate] = useState(false);
  const [metrcApiKey, setMetrcApiKey] = useState('');
  const [processStatus, setProcessStatus] = useState(user ? user.loadFromMetrcComplete : 0);

  useEffect(() => {
    if (needValid) {
      const {errors} = validHandler({metrcApiKey}, validation);
      setErrors(errors);
    }
  }, [metrcApiKey]);

  useEffect(() => {

   if (processUpdate) {
      setInterval(() => {
        getStatusUpdate()
          .then(res => {
            setProcessStatus(res.percent);
            if (res.percent == 100) {
              localStorage.setItem('showAlertSuccesSync', 1);
              document.location.reload();
            }
          });
      }, 1000);
    }
  }, [processUpdate]);

  const submitForm = e => {
    e.preventDefault();
    const {valid, errors} = validHandler({metrcApiKey}, validation);
    setErrors(errors);
    if (!valid) {
      setNeedValid(true);
    } else {
      setLockForm(true);
      addMetrcApiKey(metrcApiKey)
        .then(() => {
          getPackagesFromMetrc();
          setProcessUpdate(true);
        });
    }
  };


  return (
    <div className="form-block">
      <form action="" onSubmit={submitForm}>
        <TextField
          id="metrcApiKey"
          type="text"
          fieldName="Metrc Api Key"
          value={metrcApiKey}
          errors={errors}
          disabled={lockForm}
          onChange={e => setMetrcApiKey(e.target.value)}
        />

        <Button
          type="submit"
          color="green"
          disabled={lockForm}
        >
          Activate or Add
        </Button>
      </form>
      {processUpdate && <p>Update inventory in progress. Complete: {processStatus}% Please wait!</p>}
    </div>
  );
};

SetMetrcApiKey.propTypes = {
  user: PropTypes.any
};


export default SetMetrcApiKey;

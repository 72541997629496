import React, {useState, useEffect} from 'react';
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group';
import Main from "../../../components/main";
import Header from "../../../components/Header";
import {UserIcon, LicenseIcon, RefferalIcon, CompanyIcon} from './icons';
import {ArrowRightIcon} from '../../../assets/img/icons';
import classes from '../../../utils/classNames';
import {validHandler} from '../../../utils/validation';
import Nav from './wizardNav';
import './signup.scss';
import WizardForm from './wizardForm';
import {getLicenseTypes, register} from '../../../services/api';
import CircleButton from '../../../components/ui/CircleButton';

const SignUp = () => {
  const [tab, setTab] = useState(1);
  const [successRegistered, setSuccessRegistered] = useState(false);
  const [lockForm, setLockForm] = useState(false);
  const [needValid, setNeedValid] = useState(false);
  const [tabValid, setTabValid] = useState([false, false, false]);
  const [errors, setErrors] = useState([]);
  const [fields, setFields] = useState(localStorage.getItem('signUpFields') ? JSON.parse(localStorage.getItem('signUpFields')) : {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    repeatPassword: '',
    companyName: '',
    position: '',
    companyAddress: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    ein: '',
    licenseNumber: '',
    licenseType: '',
    issueDate: '',
    expirationDate: '',
    licenseFile: '',
    hearAboutUs: '',
    referral: ''
  });

  const [licenseTypes, setLicenseTypes] = useState([]);

  const changeTab = id => {

    let validation;
    if (id >= 2) {
      validation = ['firstName', 'lastName', 'email', 'password', 'repeatPassword'];

    }
    if (id >= 3) {
      validation = [...validation, 'companyName', 'position', 'companyAddress', 'city', 'state', 'zipCode', 'phone', 'ein'];
    }

    if (id >= 4 && tabValid.map) {
      validation = [...validation, 'licenseNumber', 'licenseType', 'issueDate', 'expirationDate', 'licenseFile'];
    }

    const {valid, errors} = validHandler(fields, validation);

    if (!valid) {
      setNeedValid(true);
    }
    setErrors(errors);
    if (valid) {
      setTab(id);
      const tv = [...tabValid];
      if (id - 2 >= 0) {
        tv[id - 2] = true;
        setTabValid(tv);
      }
      setNeedValid(false);
    } else {
      const tv = [...tabValid];
      if (id - 2 >= 0) {
        tv[id - 2] = false;
        setTabValid(tv);
      }
    }

  };

  const submitForm = e => {
    e.preventDefault();
    setLockForm(true);
    register(fields)
      .then(() => {
        setSuccessRegistered(true);
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);
        console.log(err.response.data);
      })
      .finally(() => {
        setSuccessRegistered(true);
        setLockForm(false);
      });
  };

  const fileInputHandler = e => {
    const f = {...fields};
    f[e.target.name] = e.target.files[0];
    setFields(f);
  };

  const changeHandler = e => {
    const f = {...fields};
    f[e.target.name] = e.target.value;
    setFields(f);
  };

  const selectHandler = (name, value) => {
    console.log(name, value);
    const f = {...fields};
    f[name] = value;
    setFields(f);
  };

  const selectStateHandler = (name, value) => {
    console.log(name, value);

    if (value) {
      getLicenseTypes(value)
        .then(res => {
          setLicenseTypes(res);
        });
    }



    const f = {...fields};
    f[name] = value;
    setFields(f);
  };


  const changeDateHandler = (name, value) => {
    const f = {...fields};
    f[name] = value;
    setFields(f);
  };

  useEffect(() => {
    if (needValid) {
      const validation = ['firstName', 'lastName', 'email', 'password', 'repeatPassword', 'companyName', 'position', 'companyAddress', 'city', 'state', 'zipCode', 'phone', 'ein', 'licenseNumber', 'licenseType', 'issueDate', 'expirationDate', 'licenseFile'];
      const {errors} = validHandler(fields, validation);
      setErrors(errors);
    }
    console.log(fields);
    const saveFields = {...fields};

    saveFields.password = '';
    saveFields.repeatPassword = '';
    saveFields.licenseFile = '';
    localStorage.setItem('signUpFields', JSON.stringify(saveFields));
  }, [fields]);

  return (
    <div className="signup">
      <TransitionGroup>
        <CSSTransition
          key={tab}
          classNames="fade"
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
        >
          <div className={
            classes(
              'signup__bg',
              `bg${tab}`
            )
          } />
        </CSSTransition>
      </TransitionGroup>

      <Header page="signup" />
      <Main>
        <div className="signup__steps">
          <div className="signup__steps-title">
            Step {tab}/4
          </div>
          <ul className="signup__nav">
            <Nav
              icon={UserIcon}
              id={1}
              tab={tab}
              tabValid={tabValid}
              setTab={changeTab}
            >
              User information
            </Nav>
            <Nav
              icon={CompanyIcon}
              id={2}
              tab={tab}
              tabValid={tabValid}
              setTab={changeTab}
            >
              Company information
            </Nav>
            <Nav
              icon={LicenseIcon}
              id={3}
              tab={tab}
              tabValid={tabValid}
              setTab={changeTab}
            >
              License information
            </Nav>
            <Nav
              icon={RefferalIcon}
              id={4}
              tab={tab}
              tabValid={tabValid}
              setTab={changeTab}
            >
              Referral information
            </Nav>
          </ul>
        </div>
        <div className="signup__content">
          <h1>Register</h1>
          <p className="subtitle">Follow these 4 simple steps to create your account</p>
          <div className="form-block">
            {successRegistered && <div>You are been succesfully registered. On email will send link for activate account.</div>}
            {!successRegistered && <WizardForm
              submitForm={submitForm}
              tab={tab}
              fields={fields}
              errors={errors}
              lockForm={lockForm}
              changeHandler={changeHandler}
              selectHandler={selectHandler}
              selectStateHandler={selectStateHandler}
              changeDateHandler={changeDateHandler}
              fileInputHandler={fileInputHandler}
              licenseTypes={licenseTypes}
            />}
          </div>
          {!successRegistered &&
            <div className={classes(
              'signup__nav-right',
              lockForm && 'disabled'
            )}>
              {tab != 1 &&
                <span className="back" onClick={() => changeTab(tab - 1)}>
                  Back
                </span>
              }
              {tab < 4 &&
                <span className="next" onClick={() => changeTab(tab + 1)}>
                  Next step
                  <CircleButton
                    color="green"
                    icon={ArrowRightIcon}
                  />
                </span>
              }
              {tab == 4 &&
                <span className="next" onClick={submitForm}>
                  Register
                  <CircleButton
                    color="green"
                    icon={ArrowRightIcon}
                  />
                </span>
              }
            </div>}
        </div>
      </Main>
    </div>
  );
};

export default SignUp;

import React from 'react';
import PropTypes from 'prop-types';

const Wrapper = ({children}) => (
  <div className={"wrapper wrapper-boxed"}>
    {children}
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.any
};

export default Wrapper;

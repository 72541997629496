import React from 'react';
import PropTypes from 'prop-types';
import './cartItem.scss';
import QuantityChange from '../QuantityChange';
import {useDispatch} from 'react-redux';
import {changeCart, removeFromCart} from '../../redux/actions/cartActions';
import {ReactComponent as NoPhotoIcon} from '../MarketplaceList/icon/NoPhoto.svg';

const CartItem = ({item}) => {

	const dispatch = useDispatch();

	const remove = () => {
		dispatch(removeFromCart({id: item.id}));
	};

	const setQuantity = (quantity) => {
		dispatch(changeCart({id: item.id, quantity}));
	};

	return (
		<div className="cart-item">
			<div className="cart-item__image">

				{item.inventory.preview
					? <img src={item.inventory.preview} />
					: <span className="cart-item__nophoto">
						<NoPhotoIcon />
						No Photo
					</span>
				}

			</div>
			<div className="cart-item__info">
				<div className="cart-item__top">
					<div className="cart-item__top-title">{item.inventory.name}</div>
					<div className="cart-item__top-stock"><span>In Stock:</span> {item.inventory.quantity} / {item.inventory.unitOfMeasureName}</div>
				</div>
				<div className="cart-item__characteristics">
					<ul>
						<li>Category: {item.inventory.categoryName}</li>
						{item.inventory.coa &&
							<li>COA: Yes</li>
						}
						<li>THC: {item.inventory.thc}%</li>
					</ul>
				</div>
			</div>
			<div className="cart-item__quantity">
				<div className="cart-item__top">Quantity:</div>
				<div className="cart-item__change">
					<QuantityChange
						quantity={item.quantity}
						setQuantity={setQuantity}
					/>
				</div>
			</div>
			<div className="cart-item__price">
				<div className="cart-item__top">Price:</div>
				<div className="cart-item__price-one">
					$ {item.price.toLocaleString('en-US')}
				</div>
				<div className="cart-item__price-total">
					x {item.quantity} = $ {item.total.toLocaleString('en-US')}
				</div>
			</div>
			<button className="cart-item__remove" onClick={() => remove()}>&times;</button>
		</div>
	);
};

CartItem.propTypes = {
	item: PropTypes.any
};

export default CartItem;
import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {EditIcon} from '../../assets/img/icons';
import EditablePrice from '../EditablePrice';
import TableItem from '../TableItem';
import TableMedia from '../TableMedia';
import Checkbox from '../ui/Checkbox';
import {ReactComponent as ViewIcon} from './icons/view.svg';
import classes from '../../utils/classNames';

const InventoryItem = ({item, selectedItems, onSavedMedia, onSelectItem, setInventoryPublished}) =>
  <TableItem key={item.id}>
    <>
      <Checkbox
        id={item.id}
        checked={selectedItems.find(i => i === item.id)}
        onChange={e => onSelectItem(e, item.id)}
      />
      <Link to={`/inventory/${item.id}`}>{item.name}</Link>
    </>
    <EditablePrice
      id={item.id}
      value={item.price}
    />
    <>
      {item.quantity} / {item.unitOfMeasureName}
    </>
    {item.category}
    <TableMedia
      id={item.id}
      media={item.media}
      onSavedMedia={onSavedMedia}
    />
    <>
      <button className={
        classes(
          'inventory__published-button',
          !item.isPublished && 'hide'
        )}
        onClick={() => setInventoryPublished(item.id, item.isPublished)}>
        <ViewIcon />
      </button>
      <Link
        to={`/inventory/${item.id}`}
        className='inventory__edit-button'
      >
        <EditIcon />
      </Link>
    </>
  </TableItem>;

InventoryItem.propTypes = {
  item: PropTypes.any,
  selectedItems: PropTypes.array,
  onSavedMedia: PropTypes.any,
  onSelectItem: PropTypes.any,
  setInventoryPublished: PropTypes.any,
};

export default InventoryItem;

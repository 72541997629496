import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Container} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import Main from '../../../components/main';
import Header from '../../../components/Header';
import TextField from '../../../components/ui/TextField';
import Button from '../../../components/ui/Button';
//import {GoogleIcon} from '../../../assets/img/icons';
import {validHandler} from '../../../utils/validation';
import {userLogin, userErrorAuth} from '../../../redux/actions/userActions';
import './signin.scss';

const SignIn = () => {
  const [errors, setErrors] = useState([]);
  const [lockForm, setLockForm] = useState(false);
  const [needValid, setNeedValid] = useState(false);
  const [fields, setFields] = useState({
    email: '',
    password: ''
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const isAuth = useSelector(state => state.user.isAuth);
  const errorAuth = useSelector(state => state.user.errorAuth);

  const changeHandler = e => {
    const f = {...fields};
    f[e.target.name] = e.target.value;
    setFields(f);
  };

  useEffect(() => {
    dispatch(userErrorAuth(false));
    if (needValid) {
      const validation = ['email', 'password'];
      const {errors} = validHandler(fields, validation);
      setErrors(errors);
    }
  }, [fields]);

  useEffect(() => {
    if (isAuth) {
      history.push('/');
    }
  }, [isAuth]);

  useEffect(() => {
    setLockForm(false);
  }, [errorAuth]);

  const submitForm = e => {
    e.preventDefault();
    const {valid, errors} = validHandler(fields, ['email', 'password']);
    setErrors(errors);
    if (!valid) {
      setNeedValid(true);
    } else {
      setLockForm(true);
      dispatch(
        userLogin({
          email: fields['email'],
          password: fields['password']
        })
      );
    }
  };

  return (
    <div className="signin">
      <Header page="signin" />
      <Main>
        <Container>
          <h1>Welcome Back</h1>
          <p className="subtitle">Enter your credentials to access your account</p>
          <div className="form-block">
            <form action="" onSubmit={submitForm}>
              <TextField
                id="email"
                type="email"
                fieldName="E-mail"
                value={fields['email']}
                errors={errors}
                disabled={lockForm}
                onChange={changeHandler}
              />
              <TextField
                id="password"
                type="password"
                fieldName="Password"
                value={fields['password']}
                errors={errors}
                disabled={lockForm}
                onChange={changeHandler}
              />
              {errorAuth && <span className="error">Wrong login or password</span>}
              <div className="signin__button-group">
                <Button
                  type="submit"
                  color="green"
                  disabled={lockForm}
                >
                  Login
                </Button>
                <div className="signin__forgot-link">
                  <Link to="/reset-password">Forgot Password?</Link>
                </div>
              </div>
              
                {/* <div className="signin__social-signin">
                  <span>
                    or login with:
                  </span>
                  <Button
                    type="submit"
                    color="black"
                  >
                    <GoogleIcon />Google Account
                  </Button>
                </div> */}
              
            </form>
          </div>
        </Container>
      </Main>
    </div>
  );
};

export default SignIn;
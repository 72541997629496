import React from 'react';
//import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar";
import Main from "../components/main";
import Navbar from "../components/navbar";
import Content from "../components/content";
import RightSidebar from "../components/RightSidebar";
//import Title from "../components/title";

const Dashboard = ({children, path}) => {
  // const history = useHistory();
  // const accessToken = localStorage.getItem('access_token');
  // if (!accessToken) {
  //   history.push('/sign-in');
  // }
  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar />
        <Main>
          <Navbar />
          <Content>{children}</Content>
        </Main>
        {path == '/' && <RightSidebar />}
      </Wrapper>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  children: PropTypes.any,
  path: PropTypes.string
};

export default Dashboard;


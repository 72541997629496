import * as types from "../constants";

const initialState = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    repeatPassword: '',
    companyName: '',
    position: '',
    companyAddress: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    ein: '',
    licenseNumber: '',
    licenseType: '',
    issueDate: '',
    expirationDate: '',
    licenseFile: '',
    hearAboutUs: '',
    referral: ''
  },
  isAuth: false,
  errorAuth: false
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.USER_PUT:
      return {...state, user: actions.payload};
    case types.USER_IS_AUTH:
      return {...state, isAuth: actions.payload};
    case types.USER_ERROR_AUTH:
      return {...state, errorAuth: actions.payload};
    case types.USER_GET:
    case types.USER_LOGIN:
    default:
      return state;
  }
}

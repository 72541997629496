export const STATES = [
  {value: 'Alabama', label: 'Alabama'},
  {value: 'Alaska', label: 'Alaska'},
  {value: 'Arizona', label: 'Arizona'},
  {value: 'Arkansas', label: 'Arkansas'},
  {value: 'California', label: 'California'},
  {value: 'Colorado', label: 'Colorado'},
  {value: 'Connecticut', label: 'Connecticut'},
  {value: 'Delaware', label: 'Delaware'},
  {value: 'District of Columbia', label: 'District of Columbia'},
  {value: 'Florida', label: 'Florida'},
  {value: 'Georgia', label: 'Georgia'},
  {value: 'Hawaii', label: 'Hawaii'},
  {value: 'Idaho', label: 'Idaho'},
  {value: 'Illinois', label: 'Illinois'},
  {value: 'Indiana', label: 'Indiana'},
  {value: 'Iowa', label: 'Iowa'},
  {value: 'Kansas', label: 'Kansas'},
  {value: 'Kentucky', label: 'Kentucky'},
  {value: 'Louisiana', label: 'Louisiana'},
  {value: 'Maine', label: 'Maine'},
  {value: 'Maryland', label: 'Maryland'},
  {value: 'Massachusetts', label: 'Massachusetts'},
  {value: 'Michigan', label: 'Michigan'},
  {value: 'Minnesota', label: 'Minnesota'},
  {value: 'Mississippi', label: 'Mississippi'},
  {value: 'Missouri', label: 'Missouri'},
  {value: 'Montana', label: 'Montana'},
  {value: 'Nebraska', label: 'Nebraska'},
  {value: 'Nevada', label: 'Nevada'},
  {value: 'New Hampshire', label: 'New Hampshire'},
  {value: 'New Jersey', label: 'New Jersey'},
  {value: 'New Mexico', label: 'New Mexico'},
  {value: 'New York', label: 'New York'},
  {value: 'North Carolina', label: 'North Carolina'},
  {value: 'North Dakota', label: 'North Dakota'},
  {value: 'Ohio', label: 'Ohio'},
  {value: 'Oklahoma', label: 'Oklahoma'},
  {value: 'Oregon', label: 'Oregon'},
  {value: 'Pennsylvania', label: 'Pennsylvania'},
  {value: 'Rhode Island', label: 'Rhode Island'},
  {value: 'South Carolina', label: 'South Carolina'},
  {value: 'South Dakota', label: 'South Dakota'},
  {value: 'Tennessee', label: 'Tennessee'},
  {value: 'Texas', label: 'Texas'},
  {value: 'Utah', label: 'Utah'},
  {value: 'Vermont', label: 'Vermont'},
  {value: 'Virginia', label: 'Virginia'},
  {value: 'Washington', label: 'Washington'},
  {value: 'West Virginia', label: 'West Virginia'},
  {value: 'Wisconsin', label: 'Wisconsin'},
  {value: 'Wyoming', label: 'Wyoming'},
  {value: 'Washington DC', label: 'Washington DC'},
  {value: 'Puerto Rico', label: 'Puerto Rico'},
  {value: 'U.S.Virgin Islands', label: 'U.S.Virgin Islands'},
  {value: 'American Samoa', label: 'American Samoa'},
  {value: 'Guam', label: 'Guam'},
  {value: 'Northern Mariana Islands', label: 'Northern Mariana Islands'},
  {value: 'Armed Forces Americas', label: 'Armed Forces Americas'},
  {value: 'Armed Forces Europe', label: 'Armed Forces Europe'},
  {value: 'Armed Forces Pacific', label: 'Armed Forces Pacific'}
];

export const LICENSE_DESIGNATIONS = [
  {value: 'medical', label: 'Medical'},
  {value: 'adultUse', label: 'Adult Use'},
  {value: 'other', label: 'Other'},
  {value: 'hemp', label: 'Hemp'}
];

export const LICENSE_TYPES = [
  {value: 'cultivator', label: 'Cultivator'},
  {value: 'distributor', label: 'Distributor'},
  {value: 'transporter', label: 'Transporter'},
  {value: 'manufacturer', label: 'Manufacturer'},
  {value: 'storefrontRetailer', label: 'Storefront Retailer'},
  {value: 'deliveryService', label: 'Delivery Service'},
  {value: 'cannabisEventOrganizer', label: 'Cannabis Event Organizer'},
  {value: 'testingLaboratory', label: 'Testing Laboratory'},
  {value: 'microbusiness', label: 'Microbusiness'},
  {value: 'Other', label: 'Other'}
];


export const COMPANY_TYPES = [
  {value: 'grower', label: 'Grower'},
  {value: 'extractor', label: 'Extractor'},
  {value: 'infused', label: 'Infused Products Manufacturer'},
  {value: 'dispensary', label: 'Dispensary'},
  {value: 'delivery', label: 'Delivery Provider'},
  {value: 'individual', label: 'Individual'},
  {value: 'wholesaler', label: 'Wholesaler'},
  {value: 'distributor', label: 'Distributor'},
  {value: 'processor', label: 'Processor'},
  {value: 'retailer', label: 'Retailer'},
  {value: 'other', label: 'Other'}
];

export const HEARABOUTUS = [
  {value: 'CannaInsider Podcast', label: 'CannaInsider Podcast'},
  {value: 'Conference', label: 'Conference'},
  {value: 'Press', label: 'Press'},
  {value: 'Referral', label: 'Referral'},
  {value: 'Sales Outreach', label: 'Sales Outreach'},
  {value: 'Instagram', label: 'Instagram'},
  {value: 'Search Engine', label: 'Search Engine'},
  {value: 'Word of Mouth', label: 'Word of Mouth'},
  {value: 'Canna Crew Podcast', label: 'Canna Crew Podcast'},
  {value: 'Other', label: 'Other'}
];

export const STRAIN_CLASSIFICATIONS = [
  {value: 'Sativia', label: 'Sativia'},
  {value: 'Indica', label: 'Indica'},
  {value: 'Hybrid', label: 'Hybrid'},
  {value: 'N/A', label: 'N/A'},
  {value: '1:1 CBD', label: '1:1 CBD'},
  {value: 'High CBD', label: 'High CBD'},
  {value: 'Sativia Hybrid', label: 'Sativia Hybrid'},
  {value: 'Indica Hybrid', label: 'Indica Hybrid'}
];

export const GROW_TYPE = [
  {value: 'Greenhouse', label: 'Greenhouse'},
  {value: 'Indoor', label: 'Indoor'},
  {value: 'Light Deprivation', label: 'Light Deprivation'},
  {value: 'Sun', label: 'Sun'}
];


import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import './pagination.scss';

const Pagination = ({totalCount, currentPage, pageSize, setCurrentPage, onChangePageSize}) => {
  const pageCount = Math.ceil(totalCount / pageSize);
  return (
    <div className="paginate">
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        initialPage={currentPage - 1}
        onPageChange={page => setCurrentPage(page.selected + 1)}
        containerClassName="paginate__list"
        pageLinkClassName="paginate__item-link"
        activeLinkClassName="active"
        previousLinkClassName="paginate__item-link"
        nextLinkClassName="paginate__item-link"
        breakLinkClassName="paginate__item-link"
        previousLabel="<"
        nextLabel=">"
      />
      <div className="paginate__count">
        <div className="paginate__count-title">count per page</div>
        <select 
          className="paginate__count-select"
          value={pageSize}
          onChange={onChangePageSize}
        >
          <option value={10}>10</option>
          <option value={30}>30</option>
          <option value={100}>100</option>
        </select>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  setCurrentPage: PropTypes.any,
  onChangePageSize: PropTypes.any
};

export default Pagination;
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import './actions.scss';
import Action from './Action';


const Actions = () => {
  const history = useHistory();
  const user = useSelector(state => state.user.user);
  return (
    <div className="actions">
      <div className="actions__title-block">
        <div className="actions__title">Actions</div>
      </div>
      <div className="actions__content-block">
        {user.id && !user.metrcApiKey &&
          <Action
            title="Link Your Inventory"
            buttonText="Add Inventory"
            onClickButton={() => history.push('inventory')}
          >
            Sell your inventory on the marketplace
          </Action>
        }
        <Action
          title="Refer a Cultivator"
          buttonText="Refer Cultivator"
        >
          Know any farms that need help finding buyers for their bulk product? Refer a cultivator and get a $500 credit.
        </Action>
        <Action
          title="Refer a Distributor"
          buttonText="Refer Distributor"
        >
          Know any brands or distributors in need of product? Refer a distributor and get a $500 credit.
        </Action>
      </div>
    </div>
  );
};

export default Actions;

